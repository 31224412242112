import { Maybe } from '../../../__generated__/globalTypes'

import { Amount, isAmount } from './Amount'

import planFragmentRawString from '../PlanFragment.graphql'

export const planFragmentString = planFragmentRawString

export type PlanFragment = {
  id: string
  title: string
  subtitle: Maybe<string>
  cardCost: number
  bulkCardCost: number
  backgroundStartColor: string
  backgroundEndColor: string
  color: Maybe<string>
  price: Amount
  description: Maybe<string>
  stripeId: string
  isAddon: boolean
}

export const isPlanFragment = (fragment: any): fragment is PlanFragment =>
  fragment &&
  typeof fragment.id === 'string' &&
  typeof fragment.title === 'string' &&
  (fragment.subtitle === null || typeof fragment.subtitle === 'string') &&
  typeof fragment.cardCost === 'number' &&
  typeof fragment.bulkCardCost === 'number' &&
  typeof fragment.backgroundStartColor === 'string' &&
  typeof fragment.backgroundEndColor === 'string' &&
  (fragment.color === null || typeof fragment.color === 'string') &&
  (isAmount(fragment.price) as boolean) &&
  (fragment.description === null || typeof fragment.description === 'string') &&
  typeof fragment.stripeId === 'string' &&
  typeof fragment.isAddon === 'boolean'
