import { Button, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { PlanFragment } from 'src/graphql'
import { getIconFromPlan } from 'src/pricing_page/components/pricingTile/getPlanIcon'

type AddOnTileProps = {
  title: string
  description?: string
  price: string
  onAdd?: () => void
  plan: PlanFragment
}

export const AddOnTile = (props: AddOnTileProps) => {
  const { title, price, onAdd, description, plan } = props

  return (
    <Flex
      height="fit-content"
      flexDirection="column"
      minWidth="280px"
      borderRadius="medium"
      style={{
        border: '4px solid rgba(107, 114, 128, 0.08)',
      }}
      rowGap="x1"
      backgroundColor="#fff"
    >
      <Flex
        position="relative"
        justifyContent="center"
        style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
        backgroundColor="#F9FAFB"
        inset={{ vertical: '1rem' }}
      >
        <Flex
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          inset={{ vertical: '.25rem', horizontal: '.75rem' }}
          style={{ boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.04)' }}
          borderRadius="circle"
          backgroundColor="#fff"
          position="absolute"
          top="8px"
          right="8px"
        >
          <Text
            type="body"
            content="Add-on"
            style={{ fontSize: '.625rem', color: '#404040', fontWeight: 600 }}
          />
        </Flex>
        {getIconFromPlan(plan, false)}
      </Flex>
      <Flex
        flexDirection="column"
        rowGap="x_75"
        inset={{ vertical: '.5rem', horizontal: '1.5rem' }}
      >
        <Text
          type="largeBody"
          content={title}
          lineHeight={1.2}
          style={{ fontSize: '1rem', color: '#171717', fontWeight: 600 }}
        />
        {description && (
          <Text
            type="largeBody"
            content={description}
            lineHeight={1.2}
            style={{ fontSize: '.75rem', color: '#171717', fontWeight: 600 }}
          />
        )}
        <Text
          type="largeBody"
          content={`Price: $${price}`}
          style={{ fontSize: '.875rem', color: '#6B7280', fontWeight: 400 }}
        />
        <Flex justifyContent="center" alignItems="center">
          <Button
            size="small"
            title="Add to Cart"
            backgroundColorOverride="#404040"
            textColorOverride="#fff"
            onClick={onAdd}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
