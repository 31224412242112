import React, { useState } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import {
  Button,
  Div,
  DropdownLink,
  Flex,
  Link,
  LogoProps,
  QuantumThemeWrapper,
  Spacer,
  StaticNavigation,
  Text,
  UserCard,
  UserCardLoader,
} from '@sendoutcards/quantum-design-ui'
import { StorefrontState } from 'src/redux/reducers/storefront'
import { SponsorFragment } from 'src/graphql'
import AccountSection from './AccountSection'

type StaticNavProps = {
  storefront: StorefrontState
}

const StaticNav: React.FC<StaticNavProps> = props => {
  const { storefront } = props

  const isLoading = storefront.loading && !storefront.storefront?.user
  const hasSponsor = storefront.storefront?.user.genealogyId

  const [isBioDisplayed, toggleIsBioDisplayed] = useState<boolean>(false)

  const width = useWindowWidth()
  const isMobile = width < 600

  const drawerOffset = hasSponsor ? 120 : 60

  const logo: LogoProps = {
    brand: 'soc',
    color: 'color',
    href: '/',
  }

  const getSponsorSuffix = (sponsor: SponsorFragment | undefined): string => {
    if (!sponsor) return ''

    return sponsor.genealogyId
      ? `${sponsor.genealogyId}`
      : sponsor.storefront && sponsor.storefront.slug
      ? `${sponsor.storefront.slug}`
      : ''
  }

  const links: (DropdownLink | Link)[] = [
    {
      title: 'Heartfelt Prompting Cards',
      href: `https://www.promptings.com/heartfeltsubscriptionsoverview/${getSponsorSuffix(
        storefront.storefront?.user,
      )}`,
    },
    {
      title: 'Automated System Packages',
      href: `https://www.promptings.com/automatedsystemoverview/${getSponsorSuffix(
        storefront.storefront?.user,
      )}`,
    },
    {
      title: 'Pricing',
      href: `/app/pricing`,
      target: '_self',
    },
  ]

  return (
    <QuantumThemeWrapper>
      <Div position="fixed" width="100%" zIndex={102} minWidth="320px">
        {hasSponsor && (
          <Flex
            inset={{ left: 'x3', top: 'x1' }}
            minHeight="60px"
            position="relative"
            backgroundColor="background"
            flexDirection="column"
            zIndex={22}
          >
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
            >
              {isLoading ? (
                <UserCardLoader size="small" />
              ) : (
                <>
                  <UserCard
                    profileImage={
                      storefront.storefront?.user.storefront?.mugshot?.url ??
                      storefront.storefront?.user.profileImageUrl ??
                      ''
                    }
                    firstName={storefront.storefront?.user.firstName ?? ''}
                    lastName={storefront.storefront?.user.lastName ?? ''}
                    caption={`Thanks for visiting my ${
                      storefront.storefront?.user.isAffiliate
                        ? 'affiliate'
                        : 'referral'
                    } site.`}
                    size="small"
                  />
                  <Spacer orientation="horizontal" space="x2" />
                  <Button
                    title={isBioDisplayed ? 'Show Less' : 'About Me'}
                    size="xSmall"
                    type="secondary"
                    onClick={() => toggleIsBioDisplayed(!isBioDisplayed)}
                  />
                  <Spacer orientation="horizontal" space="x2" />
                  {isBioDisplayed && !isMobile && (
                    <Text type="body">
                      {storefront.storefront?.user.storefront?.biography}
                    </Text>
                  )}
                </>
              )}
            </Flex>
            {isMobile && isBioDisplayed && (
              <Flex justifyContent="center" alignItems="center">
                <Text type="body">
                  {storefront.storefront?.user.storefront?.biography}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        <StaticNavigation
          links={links}
          logo={logo}
          drawerOffset={drawerOffset}
          accountSection={<AccountSection />}
        />
      </Div>
    </QuantumThemeWrapper>
  )
}

export default StaticNav
