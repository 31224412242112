import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'

type FeatureTextType = {
  text: string
  color?: string
  size?: string
  weight?: 'regular' | 'semiBold' | 'bold' | 'extraBold'
}

export type FeatureItemProps = {
  icon: IconType
  iconColor?: string
  iconSize?: number
  featureCopy: FeatureTextType[]
}

export const FeatureItem: React.FC<FeatureItemProps> = ({
  icon,
  iconColor = '#059669',
  iconSize = 18,
  featureCopy,
}) => {
  return (
    <Flex justifyContent="start" alignItems="center" outset="x_75">
      <Div outset={{ right: 'x1' }}>
        <Icon name={icon} color={iconColor} size={iconSize} />
      </Div>
      <Flex flexDirection="row" flexWrap="wrap">
        {featureCopy.map((text, index) => {
          return (
            <Text
              key={index}
              type="body"
              content={text.text}
              weight={text.weight ?? 'regular'}
              style={{
                color: text.color ?? '#404040',
                fontSize: text.size ?? '1rem',
                lineHeight: 1.4,
              }}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}
