import {
  Div,
  Flex,
  Icon as QIcon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React, { useState } from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
// import { PointsConversionCard } from 'src/points_conversion/components/PointsConversionCard'
import {
  ComparisonColumn,
  ComparisonRow,
  ComparisonTable as ComparisonChart,
} from './components/ComparisonTable'
import {
  activePlans,
  tableFeatures,
} from './components/comparisonChart/chartData'
import { ActivePlan, FeatureSet } from './components/comparisonChart/type'
import { PricingRoute } from './routes/PricingRoute'
import { GreetingCardRibbon } from './components/GreetingCardRibbon'
import { GlassBackgroundContainer } from './components/GlassBackground'
import { PlanTiles } from 'src/pricing_page/components/pricingTile/PlanTiles'
import { ContentBlock } from './components/ContentBlock'
import { FeatureItemChart } from './components/featureChart/FeatureChart'
import {
  featureValuesCol1,
  featureValuesCol2,
} from './components/featureChart/featureValues'
import { FeatureCardGrid } from './components/featureCard/FeatureCardGrid'
import { PromoContextProvider } from 'src/promo/context/promoContext'
import Promo from 'src/promo/containers/Promo'
import { PlanFragment } from 'src/graphql'
import { AddOnTiles } from './components/addOnTile/AddOnTiles'
import { useWindowSize } from '@react-hook/window-size'
import { PromoPlanTile } from './components/promoPlanTile/PromoPlanTile'

type PricingPageProps = {
  route?: PricingRoute
}

export const PricingPage: React.FC<PricingPageProps> = ({ route }) => {
  const [shouldShowComparisonChart, setShouldShowComparisonChart] = useState(
    false,
  )
  const [selectedPlan, setSelectedPlan] = useState(
    activePlans[ActivePlan.HRFLT],
  )
  const [promoPlanId, setPromoPlanId] = useState<string | undefined>()

  const [deviceWidth, _] = useWindowSize()
  const isTabletOrSmaller = deviceWidth <= 768
  const isTablet = deviceWidth < 950
  const isMobile = deviceWidth <= 520

  const planColumns = (Object.keys(activePlans) as Array<
    keyof typeof activePlans
  >).map(planKey => {
    const isFeatured = activePlans[planKey].isFeatured
    const isSimilar = activePlans[planKey]?.mostSimilar
    const id = activePlans[planKey].id

    return (
      <ComparisonColumn
        key={planKey}
        backgroundColor={
          isFeatured ? '#3cbede' : isSimilar ? '#e463ef' : '#8B8B8B'
        }
        heading={planKey}
        onClick={() => setSelectedPlan(selectedPlan)}
        isActive={selectedPlan.id === id}
      >
        {(Object.keys(activePlans[planKey].tableFeatures) as Array<
          keyof FeatureSet
        >).map((featureKey, index) => {
          const feature = activePlans[planKey].tableFeatures[featureKey]

          const mainFeature =
            feature.main === 'excluded' ? (
              <Icon name="x" color="#e6525d" size={20} />
            ) : feature.main === 'included' ? (
              <Icon name="check" color="#20966f" />
            ) : feature.main === 'included_with_stamps' ? (
              <Icon name="double_check" color="#20966f" />
            ) : (
              <Text
                type="caption"
                content={feature.main}
                style={{ fontWeight: 600, color: '#606060' }}
              />
            )

          const secondaryFeature = feature.secondary?.content

          return (
            <ComparisonRow
              key={featureKey}
              alignment="center"
              backgroundColor={index % 2 ? '#f8f8f8' : '#fcfcfc'}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {mainFeature}
                {secondaryFeature && (
                  <>
                    <Spacer space="x1" orientation="vertical" />
                    <Text
                      type="footnote"
                      content={secondaryFeature}
                      style={{
                        fontSize: '8px',
                        color: '#676767',
                      }}
                      whiteSpace="nowrap"
                    />
                  </>
                )}
              </div>
            </ComparisonRow>
          )
        })}
      </ComparisonColumn>
    )
  })

  const titleColumn = (
    <ComparisonColumn hasTitleRows={true}>
      {Object.keys(tableFeatures).map((featureKey, index) => (
        <ComparisonRow
          key={featureKey}
          backgroundColor={index % 2 ? '#f8f8f8' : '#fcfcfc'}
        >
          {tableFeatures[featureKey as keyof typeof tableFeatures]}
        </ComparisonRow>
      ))}
    </ComparisonColumn>
  )

  const columns = [titleColumn, ...planColumns]

  return (
    <Flex flexDirection="column" alignContent="center" alignItems="center">
      {/* --------TitleSection---------- */}
      <GlassBackgroundContainer
        blobStart="center"
        blobs={[
          {
            shape: 'circle',
            size: '40vh',
            gradient:
              'linear-gradient(to bottom, rgb(187, 247, 208), rgb(134, 239, 172), rgb(59, 130, 246))',
            filter: 'blur(200px)',
          },
          {
            shape: 'circle',
            size: '250px',
            gradient:
              'linear-gradient(to bottom, rgb(187, 247, 208), rgb(134, 239, 172), rgb(59, 130, 246))',
            filter: 'blur(120px)',
            offset: { top: '40vh', left: '-250px' },
          },
        ]}
      >
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          inset={{ top: '88px', bottom: '140px' }}
          rowGap="20px"
        >
          <Text
            type="body"
            content={'Reach Out In'}
            style={{
              fontWeight: 400,
              fontSize: isMobile ? '20px' : isTabletOrSmaller ? '30px' : '45px',
              color: '#323232',
            }}
            whiteSpace="pre"
            alignment="center"
          />
          <Text
            type="title"
            content={'Kindness'}
            style={{
              fontWeight: 700,
              fontSize: isMobile
                ? '20px'
                : isTabletOrSmaller
                ? '30px'
                : isTablet
                ? '54px'
                : '72px',
              color: '#323232',
              letterSpacing: '1.2px',
            }}
            // inset={{ bottom: isTabletOrSmaller ? '' : '16px' }}
            whiteSpace="pre"
            alignment="center"
            lineHeight={1.2}
          />
          <Text
            type="largeBody"
            content="with our Heartfelt Prompting Card Subscriptions, Automated Sending Packages, and Card Packs!"
            style={{
              color: '#6B7280',
            }}
          />
          <GreetingCardRibbon
            cards={[
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/LbZF2NNVXZTeIlYDbPGQihdk8FY=/fit-in/700x0/system_images/2023/3/17/c9a24b74-2424-4579-8cb0-83a8c6de9350.png',
                isHorizontal: true,
              },
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/0MNyTUIJ59tbLBw2n9ubQZKcM7s=/fit-in/700x0/system_images/2023/4/5/5c83f228-2278-4325-84c6-9999a9220704.png',
                isHorizontal: true,
              },
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/gutbIHDftPcwLonheIyiFhFDx2M=/fit-in/700x0/system_images/2020/5/11/97196a9c-1f90-46f7-9fae-89997c072e9e.png',
                isHorizontal: false,
              },
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/2uMm_Q4ksBhBE4VuIKwAVI8cPXM=/fit-in/700x0/system_images/2023/8/8/6ded38b0-230b-41e3-8076-08525946bfda.png',
                isHorizontal: true,
              },
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/bjX4f6px8zmLTfDmFnLsoJ2Oodo=/fit-in/700x0/system_images/2019/12/4/711fab8f-92ac-4cb1-ad0f-383569b68f35.png',
                isHorizontal: true,
              },
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/2uMm_Q4ksBhBE4VuIKwAVI8cPXM=/fit-in/700x0/system_images/2023/8/8/6ded38b0-230b-41e3-8076-08525946bfda.png',
                isHorizontal: true,
              },
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/gutbIHDftPcwLonheIyiFhFDx2M=/fit-in/700x0/system_images/2020/5/11/97196a9c-1f90-46f7-9fae-89997c072e9e.png',
                isHorizontal: false,
              },
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/RuktyCCElGEAY0ZYvbV3qMiRbMY=/fit-in/320x0/system_images/2023/4/5/5c83f228-2278-4325-84c6-9999a9220704.png',
                isHorizontal: true,
              },
              {
                previewUrl:
                  'https://www.sendoutcards.com/thumbnail/LbZF2NNVXZTeIlYDbPGQihdk8FY=/fit-in/700x0/system_images/2023/3/17/c9a24b74-2424-4579-8cb0-83a8c6de9350.png',
                isHorizontal: true,
              },
            ]}
            cardWidth={'110px'}
            cardHeight={'154px'}
            archBasePx={40}
          />
          <Div height="130px" />
          <PromoPlanTile
            planId="122"
            recurrence="/10 cards"
            extraContent="cards good for 20 days"
            action={{
              title: 'Buy Now',
              onClick: (plan: PlanFragment) => setPromoPlanId(plan.id),
            }}
          />
        </Flex>
        {/* -------- Subscriptions ---------- */}
        <Spacer space="x5" />
        <ContentBlock
          caption="Create genuine human connection with the"
          title={{ title: 'Heartfelt Prompting Card', bolded: 'Subscriptions' }}
          body={[
            'The best way to reach out in kindness and make a difference in the world is with our subscriptions. Send a unique card to one person with a heartfelt message and even add a gift for a special impact!',
          ]}
        />
        <Div display="flex" width="100%" justifyContent="space-between">
          <PlanTiles
            planIds={['1', '6', '128', '129']}
            onSelect={planId => setPromoPlanId(planId)}
          />
        </Div>
        {/* ------- Subscription Comparison Chart ----- */}
        <Div
          inset={{
            bottom: shouldShowComparisonChart ? '200px' : 'unset',
            top: isMobile ? 'x6' : 'x3',
          }}
          width="100%"
          justifyContent="center"
          display="flex"
          flexWrap="wrap"
        >
          <Text
            alignment="center"
            type="footnote"
            content="* Incentives may change through time and different plans and packages."
            outset={{ bottom: 'x3' }}
          />

          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            // onClick={() =>
            //   setShouldShowComparisonChart(!shouldShowComparisonChart)
            // }
            outset={{ bottom: 'x5' }}
          >
            <Flex
              backgroundColor={
                shouldShowComparisonChart ? 'transparent' : '#404040'
              }
              style={
                shouldShowComparisonChart
                  ? { border: '2px solid #404040', opacity: 0 }
                  : { opacity: 0 }
              }
              inset={{ horizontal: 'x3', vertical: 'x1_5' }}
              borderRadius="circle"
              cursor="pointer"
              onClick={() => setShouldShowComparisonChart(false)}
            >
              <Text
                content={`${
                  shouldShowComparisonChart ? 'Hide' : 'View'
                } Comparison Chart`}
                color={
                  shouldShowComparisonChart
                    ? 'primaryHeading'
                    : 'inverseHeading'
                }
                type="body"
              />
              <Spacer space={'x1'} orientation="horizontal" />
              <Flex
                alignItems="center"
                justifyContent="center"
                style={{
                  transform: shouldShowComparisonChart
                    ? 'rotate(90deg)'
                    : 'rotate(-90deg)',
                }}
              >
                <QIcon
                  size="small"
                  name="chevron"
                  primaryColor={
                    shouldShowComparisonChart
                      ? 'primaryHeading'
                      : 'inverseHeadingText'
                  }
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            overflowX="auto"
            inset={{ bottom: `${shouldShowComparisonChart ? '20px' : 'x0'}` }}
            height={shouldShowComparisonChart ? 'auto' : '0px'}
          >
            <ComparisonChart>{columns}</ComparisonChart>
          </Flex>
        </Div>
      </GlassBackgroundContainer>
      {/* ------- Automated System Packages ----- */}
      <Spacer space="x9" />
      <ContentBlock
        caption="Simplify your plug and play efforts"
        title={{ title: 'Automated System', bolded: 'Packages' }}
        body={[
          'These are the perfect addition to your Heartfelt Card subscriptions or as a stand-alone program to follow-up and connect with your network.',
        ]}
      />
      <PlanTiles
        planIds={['130', '131', '132']}
        onSelect={planId => setPromoPlanId(planId)}
      />
      <Spacer space="x6" />
      <Flex>
        <Text
          type="largeBody"
          weight="semiBold"
          color="primaryHeading"
          content="All 3 Automated Sending Packages include the following Bonuses:"
        />
      </Flex>
      <Spacer space="x3" />
      <FeatureItemChart
        isStacked={false}
        columnOne={featureValuesCol1}
        columnTwo={featureValuesCol2}
      />
      <Spacer space="x9" />
      {/* ------- Card Packages ----- */}
      <ContentBlock
        caption="Send how you want and when you want with"
        title={{ title: 'Card', bolded: 'Packs' }}
        body={[
          'The choice is yours with Card Packs! Send one card to one person, one card to groups of people, schedule your cards, or send yourself a bulk of cards that you hand out (or mail!) yourself.',
        ]}
      />
      <Spacer space="x3" />
      <PlanTiles
        planIds={['133', '134', '135', '136', '137']}
        layout="grid"
        onSelect={planId => setPromoPlanId(planId)}
      />
      <Spacer space="x3" />
      {/* ------- VA Time Blocks ----- */}
      <ContentBlock
        caption="Get personal attention on your account with our"
        title={{ title: 'Virtual Assistant', bolded: 'Time Blocks' }}
        body={[
          'The Quick Connect Package is included in the Automated System Packages but you can purchase it individually and get the extra help you need!',
          'Time blocks are designed to allow you the additional flexibility to purchase as you need it, no subscription required.',
        ]}
      />
      <Spacer space="x3" />
      <PlanTiles
        planIds={['140', '138', '139']} // Production ids
        // planIds={['172', '170', '171']} // Alpha ids
        layout="grid"
        onSelect={planId => setPromoPlanId(planId)}
      />
      {/* ------- Seminars ----- */}
      <ContentBlock
        caption="Learn all about the 'promptings way' to prosperity and well being"
        title={{ title: 'Promptings Academy', bolded: 'Courses' }}
        body={[
          'With online courses and live events, you will learn how the promptings process connects you with people and purpose',
        ]}
      />
      <Spacer space="x3" />
      <PlanTiles
        planIds={['146', '144', '145']}
        layout="grid"
        onSelect={planId => setPromoPlanId(planId)}
      />

      <Spacer space="x9" />
      <GlassBackgroundContainer
        blobStart="center"
        blobs={[
          {
            shape: 'circle',
            size: '40vh',
            gradient: 'linear-gradient(to bottom, #fa39ad 40%, #fe6c4c 50%)',
            filter: 'blur(200px)',
          },
          {
            shape: 'circle',
            size: '250px',
            gradient: 'linear-gradient(to bottom, #fa39ad 40%, #fe6c4c 50%)',
            filter: 'blur(120px)',
            offset: { top: '40vh', left: '-250px' },
          },
        ]}
      >
        <FeatureCardGrid />
      </GlassBackgroundContainer>
      <Spacer space="x5" />
      <ContentBlock
        caption="Create genuine human connection with the"
        title={{ title: 'Store', bolded: 'Add-ons' }}
        body={[
          'Enhance your card sending creation by personalizing your account with your own handwriting font and signatures. ',
        ]}
      />
      <Spacer space="x6" />
      <Div display="flex" width="100%" justifyContent="space-between">
        <AddOnTiles
          planIds={['141', '142', '143']}
          onSelect={(plan: PlanFragment) => setPromoPlanId(plan.id)}
        />
      </Div>
      {promoPlanId && (
        <PromoContextProvider>
          <Promo
            planId={promoPlanId}
            checkoutOnly={true}
            onClose={() => setPromoPlanId(undefined)}
          />
        </PromoContextProvider>
      )}
    </Flex>
  )
}
