import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React, { useState } from 'react'
import { Features, Tile } from './components/Tile'
import { StorytellingModal } from '../Storytelling/StorytellingModal'
import HeartFeltStepOne from '../Storytelling/SVGs/HeartFeltStepOne'
import HeartFeltStepTwo from '../Storytelling/SVGs/HeartFeltStepTwo'
import HeartFeltStepThree from '../Storytelling/SVGs/HeartFeltStepThree'
import { BasicPlanDivider } from './components/BasicPlanDivider'
import { getIconFromPlan } from './getPlanIcon'
import { getPlanPillDescription } from './getPlanPill'
import { PlanFragment } from '../../../graphql/fragments/__generated__/PlanFragment'
import useGetPlans from './useGetPlansQuery'

type PlanDetail = {
  detail: string
  innerDetails?: PlanDetail[]
}

export type PlanTilesProps = {
  planIds: string[]
  layout?: 'scroll' | 'grid'
  onSelect: (planId: string, isKeepButton?: boolean) => void
  title?: string
  description?: string
  featuredPlanId?: string
}

// TODO: Make stories a prop using planId to launch with correct plan
const heartfeltStorySteps = [
  {
    children: <HeartFeltStepOne />,
    content: [
      'In a world where genuine connections often get lost in the rush of everyday life, SendOutCards offers you the chance to be a beacon of kindness with our Heartfelt Subscription for just $37.',
      'Imagine touching lives, one heartfelt card at a time, effortlessly and affordably.',
    ],
  },
  {
    children: <HeartFeltStepTwo />,
    content: [
      'With the Heartfelt Subscription, you can send out 20 personalized cards in 30 days, brightening birthdays, saying thank you, offering encouragement, or simply sending warm greetings. Your subscription doesn`t just make a difference – it creates a ripple effect of positivity.',
    ],
  },
  {
    children: <HeartFeltStepThree />,
    content: [
      'Don`t miss your chance to make the world a better place, one heartfelt prompting card at a time. Get the Heartfelt Subscription today, be a difference-maker, and let your heart shine through your cards. Subscribe now and start the wave of kindness! 💌',
    ],
  },
]
const unlimitedHeartFeltStorySteps = [
  {
    children: <HeartFeltStepOne />,
    content: [
      "In a world where connections matter more than ever, there's a subscription that lets you spread kindness limitlessly. Introducing the Unlimited Heartfelt Subscription for just $97. With this extraordinary offer you can send up to 25 personalized cards every day, making a world of difference with each heartfelt message you send.",
    ],
  },
  {
    children: <HeartFeltStepTwo />,
    content: [
      "Imagine the impact of touching lives every single day. With the Unlimited Heartfelt Subscription, you're not just sending cards; you're sending love, support, and joy to those who matter most to you. Whether it's birthdays, anniversaries, or simply reaching out to brighten someone's day, you have the power to strengthen relationships and foster deeper connections.",
    ],
  },
  {
    children: <HeartFeltStepThree />,
    content: [
      'By joining this subscription, you become a catalyst for positivity. Your daily acts of kindness will inspire those around you to do the same, creating a wave of love and goodwill.',
      "Don't miss the opportunity to make a monumental difference in the lives of others. Embrace the Unlimited Heartfelt Subscription today and let your heart shine through your limitless cards. Subscribe now and become a beacon of kindness in a world that needs it.",
    ],
  },
]

export const PlanTiles: React.FC<PlanTilesProps> = props => {
  const { title, description, onSelect, planIds, layout = 'scroll' } = props
  const [selectedLearnMoreId, setSelectedLearnMoreId] = useState('')
  const [selectedPlan, setSelectedPlan] = useState<string | null>('6')
  const [shouldShowBasicPlan, setShouldShowBasicPlan] = useState<boolean>(false)
  const plans = useGetPlans()

  // const getOrderFromDescription = (description: string | null) => {
  //   if (!description) return 0
  //   try {
  //     const parsedDescription = JSON.parse(description)
  //     return parsedDescription.order ?? 0
  //   } catch (error) {
  //     return 0
  //   }
  // }

  const activePlans: PlanFragment[] = planIds
    .map(id => plans?.find((plan: PlanFragment) => id === plan.id))
    .filter((plan: PlanFragment | undefined): plan is PlanFragment => !!plan)

  return (
    <Flex
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyItems={'center'}
    >
      <Flex
        flexDirection="column"
        maxWidth="1064px"
        inset={{ top: 'x4', bottom: 'x4' }}
      >
        <Text
          type="body"
          content={title}
          style={{ fontSize: '2.25rem', fontWeight: 700 }}
          alignment="center"
          color="primaryHeading"
          inset={{ bottom: 'x1_5' }}
          lineHeight={1.6}
        />
        <Text
          type="body"
          content={description}
          style={{ fontSize: '1rem', fontWeight: 500 }}
          alignment="center"
          color="primaryBody"
          lineHeight={1.5}
        />
      </Flex>
      <Div width="100%" inset="x4" overflowX="auto">
        <Div
          display="flex"
          flexDirection="row"
          columnGap={layout === 'grid' ? '0px' : '24px'}
          rowGap="24px"
          width="100%"
          justifyContent={'center'}
          flexWrap={layout === 'grid' ? 'wrap' : 'nowrap'}
        >
          {activePlans.map((plan: PlanFragment) => {
            if (!plan.description) return null
            const description = JSON.parse(plan.description)
            const isBasic = plan.id === '1'

            const handlePlanUpgrade = () => {
              setSelectedPlan(plan.id)
              onSelect(plan.id)
            }

            const planFeatures: Features[] =
              description?.details?.map((detail: PlanDetail) => {
                if (detail.innerDetails) {
                  return {
                    feature: detail.detail,
                    subFeatures: detail.innerDetails.map(
                      (d: PlanDetail) => d.detail,
                    ),
                    isNotIncluded: false,
                  }
                }
                return detail.detail
              }) || []
            // TODO: Update PricingCard Check list to accept different icons, ??iconColors??

            const isActivePlan = selectedPlan === plan.id
            return (
              planFeatures && (
                <>
                  {isBasic && (
                    <BasicPlanDivider
                      text="Show Basic Plan"
                      onClick={() =>
                        setShouldShowBasicPlan(!shouldShowBasicPlan)
                      }
                    />
                  )}
                  <Flex
                    width="100%"
                    maxWidth="400px"
                    justifyContent="center"
                    outset={{
                      bottom: selectedPlan === plan.id ? 'x2' : 'x0',
                    }}
                    inset={{ horizontal: 'x2' }}
                    style={{
                      display: `${
                        isBasic && !shouldShowBasicPlan ? 'none' : 'inherit'
                      }`,
                    }}
                  >
                    <Tile
                      onSelectTile={() => setSelectedPlan(plan.id)}
                      key={plan.id}
                      itemId={plan.id}
                      title={plan.title}
                      description={plan.subtitle ?? ''}
                      price={isBasic ? '' : plan.price.description}
                      priceSubtext={
                        isBasic
                          ? 'pay as you go'
                          : plan.isAddon
                          ? '/pkg'
                          : '/mo'
                      }
                      primaryAction={{
                        title: `Buy ${plan.title}`,
                        onClick: () => handlePlanUpgrade(),
                      }}
                      features={planFeatures}
                      isActive={isActivePlan}
                      background={
                        isActivePlan
                          ? `linear-gradient(156deg, ${plan.backgroundStartColor} -8.15%, ${plan.backgroundEndColor} 129.98%), #FFF`
                          : '#FFF'
                      }
                      textColor={plan.color ?? '#535F66'}
                      icon={getIconFromPlan(plan, isActivePlan)}
                      pillTitle={getPlanPillDescription(plan.id)}
                      learnMoreAction={
                        isBasic
                          ? undefined
                          : {
                              title: 'View More',
                              onClick: () => setSelectedLearnMoreId(plan.id),
                            }
                      }
                    />
                  </Flex>
                  {/* TODO: Wire storytellingModal up to planStories Prop */}
                  {!isBasic && selectedLearnMoreId === plan.id && (
                    <StorytellingModal
                      onClose={() => setSelectedLearnMoreId('')}
                      action={{
                        title: description.plan,
                        onClick: () => handlePlanUpgrade(),
                      }}
                      selectedItem={{
                        id: plan.id,
                        title: description.plan,
                        price: plan.price.description,
                        icon:
                          plan.id === '6'
                            ? 'smile_card_w_heart'
                            : 'smile_card_w_hearts',
                        backgroundColor:
                          plan.id === '6' ? '#4AB4D8' : '#F862A6',
                        recurrence: 'month',
                      }}
                      steps={
                        plan.id === '6'
                          ? heartfeltStorySteps
                          : unlimitedHeartFeltStorySteps
                      }
                    />
                  )}
                </>
              )
            )
          })}
        </Div>
      </Div>
    </Flex>
  )
}
