import connect from 'src/redux/connect'
import React, { useEffect, useState } from 'react'
import { GetProps } from 'react-redux'
import { useWindowWidth } from '@react-hook/window-size'
import {
  Button,
  Card,
  Div,
  Flex,
  Grid,
  GridItem,
  Image,
  QuantumThemeWrapper,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  CreateSparseUser,
  CreateSparseUserData,
  GetMarketingURL,
  GetMarketingURLData,
  InviteFragment,
} from 'src/graphql'
import { performOperation } from 'src/app/api'
import { openApp } from 'src/helpers/appHelpers'
import { Transition } from 'src/chrome'
import BulkPromo from 'src/bulk/components/BulkPromo'
import { resolveImageUrl } from 'src/helpers/resolveImageUrl'
import { SOCSVG } from '../components/SOCSVG/SOCSVG'

const open = (href: string) => () => {
  window.location.href = href
}

const openGiftStore = open('https://app.sendoutcards.com/store')

const maxTitleTextWidth = '1400px'
const maxContentWidth = '1600px'

type SmallFeatureProps = {
  imageUrl: string
  title: string
  body: string
  buttonTitle: string
  buttonOnClick: () => void
}

const SmallFeature = ({
  imageUrl: imageSrc,
  title,
  body,
  buttonTitle,
  buttonOnClick,
}: SmallFeatureProps) => (
  <Flex
    flexDirection="column"
    width="340px"
    height="580px"
    inset="x2"
    alignItems="center"
  >
    <Div width="240px" height="300px">
      <Image
        image={{ url: imageSrc }}
        width="100%"
        height="100%"
        isActive={true}
      />
    </Div>
    <Spacer space="x1" />
    <Text
      whiteSpace="nowrap"
      content={title}
      alignment="center"
      type="subtitle"
    />
    <Spacer space="x1" />
    <Text content={body} type="body" alignment="center" />
    <Div flexGrow={1} />
    <Button
      title={buttonTitle}
      onClick={() => buttonOnClick()}
      outlined={true}
      backgroundColorOverride="#09E0E4"
    />
  </Flex>
)

const ProductImageGrid = ({
  imageUrls,
}: {
  imageUrls: [string, string, string, string]
}) => {
  const width = useWindowWidth()
  const getGridWidth = (width: number) => {
    if (width > 1024) {
      return 448
    } else if (width > 767) {
      return 320
    } else if (width > 474) {
      return 448
    } else {
      return 320
    }
  }
  const gridWidth = getGridWidth(width)
  return (
    <Div minWidth={`320px`}>
      <Grid
        columnSize={gridWidth === 320 ? 104 : 208}
        rowSize={gridWidth === 320 ? 54 : 75}
        gap={16}
        maxWidth={gridWidth}
      >
        {imageUrls.map((url, index) => {
          return (
            <GridItem
              key={index}
              gridColSpan={1}
              gridRowSpan={
                index === 0 ? 3 : index === 1 ? 2 : index === 2 ? 3 : 2 // TODO Will need to write a function to place the portrait images in the correct places :(
              }
            >
              <Image
                image={{ url }}
                width="100%"
                height="100%"
                isActive={true}
              />
            </GridItem>
          )
        })}
      </Grid>
    </Div>
  )
}

const Divider = ({ width }: { width?: GetProps<typeof Div>['width'] }) => (
  <Div
    outset={{ vertical: 'x2' }}
    backgroundColor={{ swatch: 'blueScale', shade: '_600' }}
    height="x1"
    width={width}
  />
)

const LargeFeature = ({
  title,
  body,
  buttonTitle,
  buttonOnClick,
  imageUrls,
  flexDirection = 'row',
}: {
  title: string
  body: string
  buttonTitle: string
  buttonOnClick: () => void
  imageUrls: [string, string, string, string]
  flexDirection?: 'row' | 'row-reverse'
}) => (
  <Flex
    inset="x8"
    flexDirection={flexDirection}
    flexWrap="wrap"
    alignItems="center"
    justifyContent="space-around"
    width="100%"
    maxWidth={maxContentWidth}
  >
    <ProductImageGrid imageUrls={imageUrls} />
    <Flex
      flexDirection="column"
      alignItems="start"
      maxWidth="560px"
      minWidth="300px"
      outset="x8"
    >
      <Text type="title">{title}</Text>
      <Divider width="100px" />
      <Text type="body">{body}</Text>
      <Spacer space="x4" />
      <Button
        title={buttonTitle}
        onClick={() => buttonOnClick()}
        fullWidth={false}
        backgroundColorOverride="#09E0E4"
      />
    </Flex>
  </Flex>
)

type ContentProps = {
  invite?: InviteFragment
}

const Content: React.FC<ContentProps> = props => {
  const { invite } = props
  const [isCreatingAccount, setIsCreatingAccount] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [heroImageStatus, setHeroImageStatus] = useState<
    'init' | 'loading' | 'loaded'
  >('init')
  const [heroImage, setHeroImage] = useState(
    'static/soc-website/images/hero.jpg',
  )

  const width = useWindowWidth()

  const getHeroImage = () => {
    setHeroImageStatus('loading')
    performOperation<GetMarketingURLData>(
      GetMarketingURL({
        title: 'HeroImage',
      }),
      result => {
        if (!result.error && result.value) {
          setHeroImage(result.value.marketingUrl.url)
        }
        setHeroImageStatus('loaded')
      },
    )
  }

  const goToApp = (query: { [key: string]: string } = {}) => {
    setIsRedirecting(true)
    openApp(``, query)
  }

  const handleSendFreeCard = () => {
    if (invite) {
      setIsCreatingAccount(true)
      performOperation<CreateSparseUserData>(
        CreateSparseUser({ inviteId: invite.id }),
        result => {
          setIsCreatingAccount(false)
          if (result.value) {
            goToApp({
              access_token: result.value.createSparseUser.account.token,
              postcardPromo: '',
            })
          } else {
            goToApp({ verify: '', postcardPromo: '' })
          }
        },
      )
    } else {
      goToApp({ verify: '', postcardPromo: '' })
    }
  }

  useEffect(() => {
    if (heroImageStatus === 'init') {
      getHeroImage()
    }
  })

  if (heroImageStatus !== 'loaded') {
    return (
      <Transition
        message={'Loading...'}
        messageStyle={{ color: 'black', fontWeight: 500 }}
      />
    )
  }

  if (isCreatingAccount || isRedirecting) {
    return (
      <Transition
        message={isRedirecting ? 'Loading...' : 'Creating guest account...'}
        messageStyle={{ color: 'black', fontWeight: 500 }}
      />
    )
  }

  return (
    <>
      <Image
        isActive={true}
        height="50vh"
        width="100%"
        image={{ url: heroImage }}
      />
      <Flex
        id="hero-image"
        backgroundSize="cover"
        justifyContent="flex-end"
        position="absolute"
        inset={{ horizontal: 'x4', vertical: 'x8' }}
        width="100%"
      >
        <Card
          zIndex={2}
          maxWidth="480px"
          backgroundColor="rgb(249, 250, 251, 0.85)"
          inset="x8"
          outset={{ top: '-24px', bottom: 'x4' }}
        >
          {/* Have to use a Flex here because Card does not respect the alignItems prop */}
          <Flex
            style={{ opacity: 1 }}
            flexDirection="column"
            alignItems="start"
          >
            <Flex
              alignItems={width < 500 ? 'center' : undefined}
              flexDirection={width < 500 ? 'column' : 'row'}
              columnGap="x1_5"
              rowGap="x1_5"
            >
              <Div
                width="fit-content"
                height="fit-content"
                inset={{ vertical: 'x1', horizontal: 'x1_5' }}
                borderRadius="20px"
                style={{
                  background:
                    'linear-gradient(45deg, #09E0E4 0%, #0FB3FF 52%,  #38DEFF 90%)',
                }}
              >
                <SOCSVG size={45} color="#fff" />
              </Div>
              <Flex flexDirection="column" rowGap="x_25">
                <Text
                  type="body"
                  isItalic={true}
                  color="primaryHeading"
                  weight="bold"
                >
                  The Promptings Mechanism
                </Text>
                <Text
                  type="title"
                  isItalic={true}
                  color="anchorBlue"
                  style={{
                    background:
                      'linear-gradient(45deg, #09E0E4 0%, #0FB3FF 52%,  #38DEFF 90%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  SendOutCards
                </Text>
              </Flex>
            </Flex>
            <Spacer space="x1" />
            <Text type="body" color="primaryBody">
              A system designed specifically to help you act on your promptings.
              The Promptings Mechanism allows you to create a process that
              attracts prosperity and connects you with people, passion, and
              purpose.
            </Text>
            <Spacer space="x4" />
            <Button
              onClick={handleSendFreeCard}
              backgroundColorOverride="#09E0E4"
              textColorOverride="#fff"
            >
              Send A Card
            </Button>
          </Flex>
        </Card>
      </Flex>
      <Div maxWidth={maxTitleTextWidth}>
        <Text
          type={width < 580 ? 'largeBody' : width < 768 ? 'subtitle' : 'title'}
          outset={{ horizontal: 'x3', vertical: 'x4' }}
          alignment="center"
        >
          Your personal assistant to creating human connections. We are here to
          deliver your promptings and help you make the difference only you can
          make.
        </Text>
      </Div>
      <Flex
        justifyContent="space-around"
        flexWrap="wrap"
        inset={{ vertical: 'x8' }}
        width="100%"
        maxWidth={maxContentWidth}
      >
        <SmallFeature
          imageUrl={resolveImageUrl('daughter-and-mother.png')}
          title="A Promptings Keepsake"
          body="Create an impact by transforming your prompting into a keepsake that will last forever. Make it uniquely yours with a personal handwriting font."
          buttonTitle="Create a Heartfelt Card"
          buttonOnClick={handleSendFreeCard}
        />
        <SmallFeature
          imageUrl={resolveImageUrl('coffee-and-laptop.png')}
          title="Relationship Development"
          body="Never miss a moment to celebrate special occasions. Stay on top of mind with your relationships through our state of the art relationship management system."
          buttonTitle="Celebrate a Relationship"
          buttonOnClick={handleSendFreeCard}
        />
        <SmallFeature
          imageUrl={resolveImageUrl('young-woman-using-phone.png')}
          title="Tangible Touch"
          body="By uniting technology with your heartfelt message, we make it easy to send a card and gift anywhere, any time."
          buttonTitle="Send a Card and Gift"
          buttonOnClick={handleSendFreeCard}
        />
      </Flex>
      <BulkPromo />
      <Div maxWidth={maxTitleTextWidth}>
        <Text type="subtitle" outset="x8" alignment="center" isItalic={true}>
          Life is full of opportunities to celebrate others. Use our Promptings
          Mechanism to reach out in kindness to others. You can even keep track
          of special occasions and send the perfect card. Who will you reach out
          to today?
        </Text>
      </Div>
      <LargeFeature
        title="Create Moments That Last Forever"
        body="Our state of the art card editor allows you to customize every greeting card you send. This easy-to-use system helps you create cards that are meaningful and memorable. By adding photos, heartfelt messages, and customizations you can make every card you send unique and personal for each recipient."
        buttonTitle="Send a Card"
        buttonOnClick={handleSendFreeCard}
        imageUrls={[
          resolveImageUrl('dad-and-mom-holding-child.png'),
          resolveImageUrl('monitor-displaying-editor.png'),
          resolveImageUrl('holly-jolly-card.png'),
          resolveImageUrl('grateful-card.png'),
        ]}
      />
      <LargeFeature
        title="Stay Top of Mind. Always."
        body="Greeting cards are perfect for any occasion, no matter how big or small. Our relationship development system keeps important dates top of mind by sending you helpful reminders. You can add notes to the contact card to document memories or meetings. Schedule multiple card sends for thank you's, birthdays, anniversaries, and the holidays, ahead of time with our campaign feature so you can stay focused on your relationships with others and stay top of mind all year long."
        buttonTitle="Send a Card"
        buttonOnClick={handleSendFreeCard}
        imageUrls={[
          resolveImageUrl('starting-something-now-card.png'),
          resolveImageUrl('sendoutcards-ios-app.png'),
          resolveImageUrl('here-for-you-card.png'),
          resolveImageUrl('thank-you-card.png'),
        ]}
        flexDirection="row-reverse"
      />
      <LargeFeature
        title="Gifts for Any Occasion"
        body="We've got the perfect gift for any and every occasion - gifts that will please the mind, heart, soul, or stomach. Include a gift to add a little boost of kindness and appreciation to your next card send!"
        buttonTitle="Shop Gifts"
        buttonOnClick={openGiftStore}
        imageUrls={[
          resolveImageUrl('best-mom-ever-locket.png'),
          resolveImageUrl('scrumptious-assortment.png'),
          resolveImageUrl('brave-little-monster.png'),
          resolveImageUrl('caramels.png'),
        ]}
      />
      <Text type="title" inset="x2">
        Love What You See?
      </Text>
      <Spacer space="x1" />
      <Text type="body" alignment="center" inset="x2">
        Find out how you can get product for free by referring your friends to
        SendOutCards, a Promptings Product.
      </Text>
      <Spacer space="x4" />
      <Flex width="100%" justifyContent="center">
        {[
          resolveImageUrl('you-and-me-card.png'),
          resolveImageUrl('selfie-queen-card.png'),
          resolveImageUrl('dad-and-mom-holding-child.png'),
          resolveImageUrl('world-needs-you-card.png'),
          resolveImageUrl('best-dad-in-the-world-card.png'),
        ].map(url => (
          <Div
            key={url}
            width="250px"
            height="350px"
            minWidth="250px"
            outset="x4"
          >
            <Image
              isActive={true}
              image={{ url }}
              width="100%"
              height="100%"
              borderRadius="none"
            />
          </Div>
        ))}
      </Flex>
      <Spacer space="x4" />
      <Button
        outlined={true}
        title="Earn Free Gifts"
        onClick={open(
          'https://www.sendoutcards.com/resources/customer-referral-program/',
        )}
        backgroundColorOverride="#09E0E4"
      />
      <Spacer space="x8" />
      <Text type="title" alignment="center" inset="x2">
        Explore the Parent Company of SendOutCards Promptings
      </Text>
      <Spacer space="x1" />
      <Div maxWidth="700px" inset="x2">
        <Text type="body" alignment="center">
          Promptings is home to multiple products and services to help you act
          on your promptings every day. Find ou how you can join the opportunity
          and share Promptings with the world.
        </Text>
      </Div>
      <Spacer space="x2" />
      <Flex width="100%" justifyContent="space-around" flexWrap="wrap">
        <Button
          title="Promptings"
          size="large"
          backgroundColorOverride="#09E0E4"
          onClick={() => window.open(`https://www.promptings.com`)}
        />
      </Flex>
      <Spacer space="x8" />
    </>
  )
}

const ContentWithProps = connect(
  state => ({
    invite: state.invites.invite,
  }),
  {},
)(Content)

const LaunchPage = () => (
  <QuantumThemeWrapper>
    <Flex
      flexDirection="column"
      alignItems="center"
      backgroundColor="rgb(249, 250, 251)"
    >
      <ContentWithProps />
    </Flex>
  </QuantumThemeWrapper>
)

export default LaunchPage
