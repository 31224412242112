import React from 'react'

export type SVGProps = {
  size?: number
  onClick?: () => void
  color?: string
}

export const SOCSVG: React.FC<SVGProps> = ({
  size = 24,
  onClick,
  color = '#000',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 114 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        d="M104.104 9.89592C98.9583 5.93759 79.1667 12.2709 71.25 15.4376C53.8333 21.3751 34.8333 30.4793 20.5833 39.5834C13.0625 44.7293 13.0625 45.5209 13.8541 46.3126L14.25 46.7084H14.6458C15.0417 46.7084 16.2292 47.1043 21.7708 43.1459C47.5 29.6876 89.0625 13.4584 98.5625 17.8126C103.708 20.1876 101.729 30.4793 98.1667 47.5001C95.3958 59.3751 92.2292 74.4168 90.6458 92.2293C89.4583 103.313 90.6458 103.708 91.4375 103.708H91.8333C92.625 103.708 93.4167 103.708 96.1875 93.4168C98.5625 80.3543 110.833 15.0418 104.104 9.89592Z"
        fill={color}
      />
      <path
        d="M47.8958 85.896C53.0416 85.896 57 81.5418 58.9791 79.1668C60.5625 77.5835 64.5208 73.2293 65.3125 72.4376C74.4166 64.1251 87.875 47.1043 98.1666 25.3335C98.5625 24.5418 98.5625 23.7501 97.7708 23.3543C96.9791 22.5626 95.7916 23.3543 94.6041 24.146V24.5418C74.4166 59.3751 61.3541 70.8543 57 74.8126C54.2291 77.1876 54.625 77.1876 53.4375 77.9793C52.6458 78.771 48.2916 82.3335 45.5208 81.9376C40.7708 81.5418 33.6458 74.8126 24.1458 61.3543C24.1458 61.3543 21.7708 58.9793 20.5833 59.771C19.3958 60.5626 20.1875 62.146 22.5625 65.3126C32.0625 80.3543 39.9791 85.896 47.8958 85.896Z"
        fill={color}
      />
      <path
        d="M82.3333 99.7503C54.625 93.417 40.7708 96.1878 31.6666 98.167C24.1458 99.7503 20.5833 100.542 14.6458 95.3961C10.6875 91.8336 11.0833 87.4795 11.0833 87.0836C11.0833 85.1045 11.0833 83.917 10.2916 83.5211C9.89579 83.1253 9.49996 83.5211 9.10413 83.5211C8.31246 84.3128 7.91663 86.292 7.91663 87.0836C7.91663 87.4795 7.91663 95.0003 14.25 99.7503C21.7708 104.104 24.1458 103.709 32.0625 103.313C39.9791 102.917 54.625 101.729 87.4791 104.5C88.6666 104.5 89.0625 104.104 89.0625 103.313C89.4583 102.521 89.0625 101.729 82.3333 99.7503Z"
        fill={color}
      />
    </svg>
  )
}
