import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { FeatureItem, FeatureItemProps } from './FeatureItem'

type FeatureChartProps = {
  columnOne: FeatureItemProps[]
  columnTwo: FeatureItemProps[]
  isStacked: boolean
}

export const FeatureItemChart: React.FC<FeatureChartProps> = ({
  columnOne,
  columnTwo,
  isStacked = false,
}) => {
  const width = isStacked ? '100%' : '50%'
  return (
    <Flex
      justifyContent="space-between"
      inset={{ horizontal: 'x2_5', vertical: 'x3' }}
      style={{ backgroundColor: 'rgba(184, 184, 184, 0.14)' }}
      borderRadius={'medium'}
      flexDirection={isStacked ? 'column' : 'row'}
      flexWrap={isStacked ? 'wrap' : 'nowrap'}
    >
      <Flex width={width} flexDirection="column">
        {columnOne.map((feature, index) => {
          return <FeatureItem key={index} {...feature} />
        })}
      </Flex>
      <Flex width={width} flexDirection="column">
        {columnTwo.map((feature, index) => {
          return <FeatureItem key={index} {...feature} />
        })}
      </Flex>
    </Flex>
  )
}
