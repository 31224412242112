import { Flex } from '@sendoutcards/quantum-design-ui'
import { AddOnTile } from './AddOnTile'
import { PlanFragment } from 'src/graphql'
import useGetPlans from '../pricingTile/useGetPlansQuery'

type AddOnTilesProps = {
  planIds: string[]
  layout?: 'scroll' | 'grid'
  onSelect: (plan: PlanFragment) => void
}

export const AddOnTiles = (props: AddOnTilesProps) => {
  const { planIds, layout = 'grid', onSelect } = props
  const plans = useGetPlans()
  const activeAddOns = plans
    ? plans.filter(plan => planIds.includes(plan.id))
    : []

  return (
    <Flex
      flexDirection="row"
      columnGap={'24px'}
      rowGap="24px"
      width="100%"
      justifyContent={'center'}
      flexWrap={layout === 'grid' ? 'wrap' : 'nowrap'}
      alignItems="center"
    >
      {activeAddOns.map(addOn => {
        const price = addOn.price.amount / 100
        return (
          <AddOnTile
            key={addOn.id}
            title={addOn.title}
            description={addOn.subtitle ?? ''}
            price={price.toString()}
            onAdd={() => onSelect(addOn)}
            plan={addOn}
          />
        )
      })}
    </Flex>
  )
}
