import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { FeatureCard } from './FeatureCard'

type FeatureCardGridProps = {}

export const FeatureCardGrid: React.FC<FeatureCardGridProps> = () => {
  return (
    <Flex flexDirection="column" outset="x9">
      <Text type="title" content="How the System Works" alignment="center" />
      <Spacer space="x2" />
      <Text
        alignment="center"
        type="body"
        content="We have the perfect way to act on promptings, no matter how you choose to use SendOutCards."
      />
      <Flex flexDirection="column" inset="x5" flexWrap="wrap" rowGap="x2">
        <Text content="Ways to Send:" type="largeBody" weight="bold" />
        <Flex columnGap={'x2_5'} rowGap="x2_5" flexWrap="wrap">
          <FeatureCard
            minWidth="350px"
            icon="automated_card"
            title="Heartfelt Prompting Card"
            description="A card with a unique heartfelt message sent to a single recipient with an immediate send date."
            footnote="Subject to fair use policy"
          />
          <FeatureCard
            minWidth="350px"
            icon="card"
            title="Heartfelt Prompting Card"
            description="A card with a unique heartfelt message sent to a single recipient with an immediate send date."
            footnote="Subject to fair use policy"
          />
        </Flex>
        <Spacer space="x2" />
        {/* Automate Sending */}
        <Flex
          flexDirection="column"
          columnGap="x2_5"
          rowGap="x2_5"
          flexWrap="wrap"
        >
          <Text
            content="Automate Your Sending With:"
            type="largeBody"
            weight="bold"
          />
          <Flex columnGap="x2_5" rowGap="x2_5" flexWrap="wrap">
            <FeatureCard
              minWidth="250px"
              isStacked={true}
              icon="campaign_card"
              iconSize={50}
              title="Campaign"
              description="A campaign is a card template you create and save to send to multiple people at once or sent one at a time. Example: A new customer welcome or thank you for your business card."
            />
            <FeatureCard
              minWidth="250px"
              isStacked={true}
              icon="group_send_card"
              iconSize={50}
              title="Group Sends"
              description="A group sends is when you create a card and then send to two or more people. Example: sending out invitations or holiday cards."
              footnote="Subject to fair use policy"
            />
            <FeatureCard
              minWidth="250px"
              isStacked={true}
              icon="schedule_card"
              iconSize={50}
              title="Scheduled Campaigns"
              description="Any card with a send date other than immediate. Example: scheduling your holiday or birthday cards."
            />
            <FeatureCard
              minWidth="250px"
              iconSize={50}
              isStacked={true}
              icon="multi_touch_card"
              title="Multiple Touch Campaign"
              description="A campaign that is sent to one or more persons over a series of dates, immediate and/or schedule. Example: sending or scheduling your thank you, birthday, anniversary, or holiday card sends."
            />
          </Flex>
        </Flex>
        <Spacer space="x2" />
        {/* Card Types */}
        <Flex flexDirection="column" rowGap="x2" flexWrap="wrap">
          <Text content="Card Types:" type="largeBody" weight="bold" />
          <Flex columnGap="x2_5" rowGap="x2_5" flexWrap="wrap">
            <FeatureCard
              minWidth="350px"
              iconSize={50}
              isStacked={true}
              icon="catalog_card"
              title="Catalog Cards"
              description="Our card catalog features thousands of beautiful designs in numerous categories."
            />
            <FeatureCard
              minWidth="350px"
              iconSize={50}
              isStacked={true}
              icon="photo_drop_card"
              title="Photo Drop Cards"
              description="These cards feature beautifully designed templates that you can drop your own photos into."
            />
            <FeatureCard
              minWidth="350px"
              isStacked={true}
              iconSize={50}
              icon="campaign_card"
              title="Build Your Own Cards"
              description="Add your own pictures, headlines, and text on the front, inside, and back panels."
            />
          </Flex>
        </Flex>
        <Spacer space="x2" />
        {/* Types of Account Storage */}
        <Flex flexDirection="column" rowGap="x2" flexWrap="wrap">
          <Text
            content="Types of Account Storage:"
            type="largeBody"
            weight="bold"
          />
          <Flex columnGap="x2_5" rowGap="x2_5" flexWrap="wrap">
            <FeatureCard
              minWidth="350px"
              iconSize={50}
              icon="enhanced_storage"
              title="Enhanced Storage"
              description="Enhanced storage allows you save 500 cards, 1,000 pictures, 100 campaigns, and 500 contacts."
            />
            <FeatureCard
              minWidth="350px"
              icon="premium_storage"
              iconSize={50}
              title="Premium Storage"
              description="Premium storage allows you to save unlimited cards, pictures, campaigns, and contacts."
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
