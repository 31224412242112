import React from 'react'

import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'

type ContentBlockProps = {
  caption: string
  title: { title?: string; bolded?: string }
  body: string[]
  color?: string
  maxWidth?: string
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  caption,
  title,
  body,
  color = '#6B7280',
  maxWidth = '1050px',
}) => {
  return (
    <Flex
      width="100%"
      maxWidth={maxWidth}
      position="relative"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex justifyContent="center" alignItems="center">
        <Text type="title" weight="semiBold" style={{ color: color }}>
          &middot;
        </Text>
        <Spacer space="x1" orientation="horizontal" />
        <Text
          type="subtitle"
          weight="regular"
          content={caption}
          style={{ color: color }}
        />
        <Spacer space="x1" orientation="horizontal" />
        <Text type="title" weight="semiBold" style={{ color: color }}>
          &middot;
        </Text>
      </Flex>
      <Flex>
        {title.title && (
          <Text
            type="title"
            weight="regular"
            content={title.title}
            style={{ color: color, fontSize: 40 }}
          />
        )}
        {title.bolded && (
          <Text
            type="title"
            weight="extraBold"
            content={title.bolded}
            style={{ color: color, fontSize: 38, marginLeft: '10px' }}
          />
        )}
      </Flex>
      <Spacer space="x3" />
      <Flex flexDirection="column" rowGap="x2">
        {body.map((str, index) => (
          <Text
            key={index}
            alignment="left"
            type="body"
            content={str}
            color="primaryHeading"
          />
        ))}
      </Flex>
    </Flex>
  )
}
