import React from 'react'

import { Div, Flex } from '@sendoutcards/quantum-design-ui'

type BlobPositionType = 'top' | 'right' | 'bottom' | 'left' | 'center'
type BlobOffset = {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

type GlassBlobType = {
  shape: 'circle'
  size: string
  gradient: string
  filter: string
  offset?: BlobOffset
}

type GlassBackgroundContainerProps = {
  blobStart: BlobPositionType
  blobs: GlassBlobType[]
  children: React.ReactNode
}

export const GlassBackgroundContainer: React.FC<GlassBackgroundContainerProps> = ({
  blobs,
  blobStart,
  children,
}) => {
  // how many pixels beyond visible area the glass should consider when calculating blur
  // tslint:disable-next-line: cyclomatic-complexity score 25
  const blobPosition = (position: BlobPositionType, offset?: BlobOffset) => {
    const defaultPosition = {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }

    switch (position) {
      case 'top':
        return {
          top: offset?.top ? `calc(0px + ${offset.top})` : '0px',
          left: offset?.left ? `calc(50% + ${offset.left})` : '50%',
          right: offset?.right ? `calc(0px + ${offset.right})` : 'unset',
          bottom: offset?.bottom ? `${offset.bottom}` : 'unset',
          transform: 'translateX(-50%)',
        }

      case 'right':
        return {
          top: offset?.top ? `calc(50% + ${offset.top})` : '50%',
          left: offset?.left ? offset.left : 'unset',
          right: offset?.right ? `calc(0px + ${offset.right})` : '0px',
          bottom: offset?.bottom ? `${offset.bottom}` : 'unset',
          transform: 'translateY(-50%)',
        }

      case 'left':
        return {
          top: offset?.top ? `calc(50% + ${offset.top})` : '50%',
          left: offset?.left ? `calc(0px + ${offset.left})` : '0px',
          right: offset?.right ? `${offset.right}` : 'unset',
          bottom: offset?.bottom ? `${offset.bottom}` : 'unset',
          transform: 'translateY(-50%)',
        }

      case 'bottom':
        return {
          top: offset?.top ? `calc(0px + ${offset.top})` : '0px',
          left: offset?.left ? `calc(50% + ${offset.left})` : '50%',
          right: offset?.right ? `${offset.right}` : 'unset',
          bottom: offset?.bottom ? `calc(0px + ${offset.bottom})` : '0px',
          transform: 'translateX(-50%)',
        }

      case 'center':
        return {
          top: offset?.top ? `calc(50% + ${offset.top})` : '50%',
          left: offset?.left ? `calc(50% + ${offset.left})` : '50%',
          bottom: offset?.bottom ? `${offset.bottom}` : 'unset',
          transform: 'translate(-50%, -50%)',
        }

      default:
        return defaultPosition
    }
  }

  return (
    <Flex
      width="100%"
      height="100%"
      position="relative"
      justifyContent="center"
      alignItems="center"
      backgroundColor="foreground"
    >
      {/* Blobs behind glass */}
      {blobs.map((blob, index) => (
        <Div
          key={index}
          position="absolute"
          top="50%"
          left="50%"
          width={blob.size}
          height={blob.size}
          style={{
            background: blob.gradient,
            filter: blob.filter,
            ...blobPosition(blobStart, blob.offset),
          }}
          borderRadius={'circle'}
        />
      ))}
      {/** Content Container */}
      <Flex
        position="relative"
        width="100%"
        height="100%"
        zIndex={5}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Flex>
    </Flex>
  )
}
