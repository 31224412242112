import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardQuantitySlider,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'

import { GetPlans, GetPlansData } from 'src/graphql'
import { openCatalog } from 'src/redux/actions/routeAliases'
import connect from 'src/redux/connect'
import { performOperation } from 'src/app/api'
import { CircularProgress } from '@material-ui/core'

type BulkPromoProps = {
  openCatalog: (bulk?: true) => void
  isMobile: boolean
}

type BulkPromoMobileProps = {
  openCatalog: (bulk?: true) => void
  quantity: number
  setQuantity: (value: number) => void
}

const BulkPromoMobile: React.FC<BulkPromoMobileProps> = props => {
  const { openCatalog, quantity, setQuantity } = props

  return (
    <Flex
      justifyContent="center"
      columnGap="x9"
      inset={{ horizontal: 'x4', vertical: 'x8' }}
      width="100%"
    >
      <Card
        zIndex={2}
        backgroundColor="background"
        maxWidth="100%"
        inset="x8"
        outset={{ top: 'x8', bottom: 'x4' }}
      >
        {/* Have to use a Flex here because Card does not respect the alignItems prop */}
        <Flex
          flexDirection="column"
          alignItems="center"
          backgroundColor="background"
        >
          <Text type="title">Send Your Cards In Bulk</Text>
          <Spacer space="x2" />
          <Text type="body">
            Sending your cards in bulk is a great option for those larger sends
            you want to further personalize. Whether you want to add an insert
            to each card, further personalize the card or envelope, or just hand
            deliver your cards, we have the tool for you.
          </Text>
          <Spacer space="x4" />
          <CardQuantitySlider
            quantity={quantity}
            setQuantity={(quantity: number) => setQuantity(quantity)}
            min={10}
            max={400}
            sliderFill="default"
            unitPrice={1.5}
            width={200}
            cardImage="https://www.sendoutcards.com/thumbnail/XtEqKx_cN-hAPb20AliL2XLTmAY=/fit-in/650x650/system_images/2019/12/4/711fab8f-92ac-4cb1-ad0f-383569b68f35.png"
            quantityDescription="cards"
            cardOrientation="horizontal"
          />
          <Spacer space="x4" />
          <Button
            onClick={() => openCatalog(true)}
            backgroundColorOverride="#09E0E4"
          >
            Send Bulk Cards
          </Button>
        </Flex>
      </Card>
    </Flex>
  )
}

const BulkPromo: React.FC<BulkPromoProps> = props => {
  const { openCatalog, isMobile } = props
  const [quantity, setQuantity] = useState<number>(100)

  const [bulkCardCostStatus, setBulkCardCostStatus] = useState<
    'init' | 'loading' | 'loaded' | 'error'
  >('init')
  const [bulkCardCost, setUnitPrice] = useState<number | undefined>(undefined)

  const getUnitPrice = useCallback(() => {
    setBulkCardCostStatus('loading')
    performOperation<GetPlansData>(GetPlans(), result => {
      if (result.error || !result.value) {
        setUnitPrice(undefined)
        setBulkCardCostStatus('error')
      } else {
        setUnitPrice(result.value.plans.find(p => p.id === '1')?.bulkCardCost)
        setBulkCardCostStatus('loaded')
      }
    })
  }, [])

  useEffect(() => {
    if (bulkCardCostStatus === 'init') {
      getUnitPrice()
    }
  }, [bulkCardCostStatus, getUnitPrice])

  if (isMobile) {
    return (
      <BulkPromoMobile
        openCatalog={openCatalog}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    )
  }

  return (
    <Flex
      justifyContent="center"
      columnGap="x9"
      inset={{ horizontal: 'x4', vertical: 'x8' }}
      width="100%"
    >
      <Card
        zIndex={2}
        backgroundColor="background"
        maxWidth="480px"
        inset="x8"
        outset={{ top: 'x8', bottom: 'x4' }}
      >
        {/* Have to use a Flex here because Card does not respect the alignItems prop */}
        <Flex
          flexDirection="column"
          alignItems="start"
          backgroundColor="background"
        >
          <Text type="title">Send Your Cards In Bulk</Text>
          <Spacer space="x2" />
          <Text type="body">
            Sending your cards in bulk is a great option for those larger sends
            you want to further personalize. Whether you want to add an insert
            to each card, further personalize the card or envelope, or just hand
            deliver your cards, we have the tool for you.
          </Text>
          <Spacer space="x4" />
          <Button
            onClick={() => openCatalog(true)}
            backgroundColorOverride="#09E0E4"
          >
            Send Bulk Cards
          </Button>
        </Flex>
      </Card>
      {bulkCardCost !== undefined ? (
        <CardQuantitySlider
          quantity={quantity}
          setQuantity={(quantity: number) => setQuantity(quantity)}
          min={10}
          max={400}
          sliderFill="default"
          unitPrice={bulkCardCost}
          width={200}
          cardImage="https://www.sendoutcards.com/thumbnail/XtEqKx_cN-hAPb20AliL2XLTmAY=/fit-in/650x650/system_images/2019/12/4/711fab8f-92ac-4cb1-ad0f-383569b68f35.png"
          quantityDescription="cards"
          cardOrientation="horizontal"
        />
      ) : bulkCardCostStatus === 'loading' ? (
        <CircularProgress />
      ) : (
        <></>
      )}
    </Flex>
  )
}

export default connect(
  state => {
    return {
      isMobile: state.window.isMobile,
    }
  },
  { openCatalog },
)(BulkPromo)
