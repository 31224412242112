import React from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import {
  Anchor,
  Div,
  Flex,
  Icon,
  QuantumThemeWrapper,
  Text,
} from '@sendoutcards/quantum-design-ui'

const Footer: React.FC = () => {
  const windowWidth = useWindowWidth()
  const isSmallMobile = windowWidth < 440

  return (
    <QuantumThemeWrapper>
      <footer>
        <Div
          height="60px"
          display="flex"
          justifyContent={isSmallMobile ? 'space-around' : 'center'}
          alignItems="center"
          position="relative"
          backgroundColor="white"
        >
          <Flex
            position={isSmallMobile ? 'relative' : 'absolute'}
            left={isSmallMobile ? '0' : '2em'}
            columnGap={isSmallMobile ? 'x1' : 'x2'}
            backgroundColor="foreground"
          >
            <Anchor
              backgroundColor="foreground"
              href="https://www.facebook.com/SendOutCards"
              target="_blank"
            >
              <Icon name={'facebook'} size="medium" primaryColor="default" />
            </Anchor>
            <Anchor
              backgroundColor="foreground"
              href="https://www.instagram.com/sendoutcards_official/"
              target="_blank"
            >
              <Icon name={'instagram'} size="medium" primaryColor="default" />
            </Anchor>
            <Anchor
              backgroundColor="foreground"
              href="https://www.linkedin.com/company/send-out-cards/"
              target="_blank"
            >
              <Icon name={'linkedIn'} size="medium" primaryColor="default" />
            </Anchor>
          </Flex>
          <Text type="body">Copyright 2021</Text>
        </Div>
      </footer>
    </QuantumThemeWrapper>
  )
}

export default Footer
