import { Anchor, Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Button } from 'src/design_system/components/Button'
// import { useSelector } from 'src/hooks'

type FeatureWithSubFeaturesType = {
  feature: string
  subFeatures: string[]
  isNotIncluded?: boolean
}
export type Features = FeatureWithSubFeaturesType | string

type ActionType = {
  title: string
  onClick?: () => void
  isDisabled?: boolean
  shouldTextMatchBackground?: boolean
}

type TileProps = {
  onSelectTile?: () => void
  itemId?: string
  icon: React.ReactNode
  title: string
  description?: string
  price?: string
  priceSubtext?: string
  primaryAction: ActionType
  features: Features[]
  textColor: string
  background: string
  accentColor?: string
  learnMoreAction?: {
    title: string
    onClick: () => void
  }
  pillTitle?: string
  isActive?: boolean
}

export const Tile = (props: TileProps) => {
  const {
    onSelectTile,
    title,
    description,
    price,
    priceSubtext,
    primaryAction,
    features,
    learnMoreAction,
    isActive,
    background,
    textColor,
    accentColor,
    pillTitle,
    icon,
  } = props

  // const isFeatureWithSubFeaturesType = (
  //   valueToCheck: Features,
  // ): valueToCheck is FeatureWithSubFeaturesType => {
  //   const value = valueToCheck as FeatureWithSubFeaturesType
  //   return value.feature !== undefined && value.subFeatures !== undefined
  // }

  // id: 6, heartfelt
  // id: 42, unlimited hearfelt

  // TODO: Remove/refactor busisness logic out of this tile so that it can re-used in other areas such as Addons/Packages.

  // const isTablet = useSelector(state => state.window.width <= 820)
  const isTablet = false
  const responsiveStyles = {
    titleSize: isTablet ? '1.2rem' : '1.385rem',
    priceSize: isTablet ? '1.5rem' : '1.875rem',
    btnTitleSize: isTablet ? '.8rem' : '1rem',
    featuresSize: isTablet ? '.7rem' : '.89rem',
    cardPadding: isTablet ? 'x2' : 'x4',
    cardGap: isTablet ? 'x2_5' : 'x4',
  }

  return (
    <Flex
      cursor={onSelectTile ? 'pointer' : 'initial'}
      onClick={onSelectTile}
      flexDirection="column"
      rowGap={responsiveStyles.cardGap}
      inset={responsiveStyles.cardPadding}
      minWidth="350px"
      style={{
        background: background,
        borderRadius: '.75rem',
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.12)',
        borderTop: `3px solid  ${accentColor} `,
        transform: `${isActive ? 'scale(1.05)' : ''}`,
        transition: 'ease 0.3s',
        paddingBottom: '24px',
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        {icon}
        <Div
          backgroundColor="#fff"
          borderRadius="circle"
          boxShadow="light"
          height="fit-content"
          inset={{ horizontal: 'x_75' }}
          outset={{ bottom: 'auto' }}
        >
          <Text
            type="body"
            content={pillTitle}
            lineHeight={2}
            style={{
              fontSize: '.625rem',
              fontWeight: 700,
              color: textColor,
            }}
          />
        </Div>
      </Flex>
      <Flex flexDirection="column">
        <Text
          type="body"
          content={description}
          lineHeight={1.3}
          color={isActive ? 'inverseBody' : 'primaryBody'}
          style={{ fontSize: '.85rem', fontWeight: 500, lineHeight: '2.185' }}
        />
        <Flex flexDirection="row" alignItems="center" columnGap="x_5">
          <Text
            type="body"
            content={title}
            color={isActive ? 'inverseHeading' : 'primaryHeading'}
            style={{
              fontSize: `${responsiveStyles.titleSize}`,
              fontWeight: 700,
            }}
          />
          {learnMoreAction && (
            <Div
              display="flex"
              alignItems="center"
              cursor="pointer"
              onClick={learnMoreAction.onClick}
              height="fit-content"
              width="fit-content"
            >
              <Icon
                name="information"
                primaryColor={isActive ? 'inverseBodyText' : 'primaryBodyText'}
                size="xSmall"
              />
            </Div>
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="row" columnGap="x_75">
        <Text
          type="body"
          content={price}
          color={isActive ? 'inverseHeading' : 'primaryHeading'}
          style={{ fontSize: `${responsiveStyles.priceSize}`, fontWeight: 500 }}
        />
        <Text
          type="body"
          content={priceSubtext}
          style={{
            fontSize: '.875rem',
            fontWeight: 500,
            color: isActive ? '#fcfcfc66' : '#6B7280',
          }}
        />
      </Flex>
      <Flex flexDirection="column" rowGap="x_5">
        {features.map((feature, index) => (
          <Flex key={index} flexDirection="column">
            <Flex flexDirection="row" alignItems="flex-start" columnGap="x_5">
              <Div
                width="fit-content"
                height="fit-content"
                inset={{ top: 'x_5' }}
              >
                <Icon
                  name="check"
                  primaryColor={isActive ? 'inverseBodyText' : textColor}
                  size="xSmall"
                />
              </Div>
              <Text
                type="body"
                content={
                  typeof feature === 'string' ? feature : feature.feature
                }
                color={isActive ? 'inverseBody' : 'primaryBody'}
                style={{
                  fontSize: `${responsiveStyles.featuresSize}`,
                  fontWeight: 500,
                }}
              />
            </Flex>
            {/* {isFeatureWithSubFeaturesType(feature) &&
              feature.subFeatures.length > 0 && (
                <List
                  orientation="vertical"
                  alignment="center"
                  width="100%"
                  outset={{ left: 'x2' }}
                  listStyle="circle"
                >
                  {feature.subFeatures.map((subFeature, subIndex) => (
                    <ListItem
                      orientation="vertical"
                      key={subIndex}
                      inset={{
                        right: 'x4',
                        left: 'x0',
                        top: '0',
                        bottom: '0',
                      }}
                    >
                      <Text
                        type="body"
                        style={{ fontSize: '.75rem', fontWeight: 500 }}
                        color={isHeartfelt ? 'inverseBody' : 'primaryBody'}
                      >
                        {subFeature}
                      </Text>
                    </ListItem>
                  ))}
                </List>
              )} */}
          </Flex>
        ))}
      </Flex>
      <Flex
        width="100%"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        outset={{ top: 'auto' }}
      >
        <Button
          title={primaryAction.title}
          textColor={textColor}
          backgroundColor="#fff"
          width="100%"
          boxShadow="0px 4px 32px 0px rgba(0, 0, 0, 0.2)"
          onClick={primaryAction.onClick}
          border={`2px solid ${isActive ? '#fff' : textColor}`}
          textSize={responsiveStyles.btnTitleSize}
        />
        {learnMoreAction && (
          <Anchor
            title={learnMoreAction.title}
            onClick={learnMoreAction.onClick}
            isDecorated={true}
            color={isActive ? 'inverseHeading' : 'primaryBody'}
            outset={{ top: 'x2' }}
            size="xSmall"
          />
        )}
      </Flex>
    </Flex>
  )
}
