// This is where you can modify the Table's titles, or add/remove features
export enum FeatureType {
  PRICE = 'Price',
  PCT_OFF = 'Percentage Off Retail Price',
  IS_VLD = 'Is Valid For',
  HPC = 'Heartfelt Promptings Cards',
  SC = 'System Cards',
  AC = 'Additional Cards',
  CM = 'Contact Manager',
  PS = 'Premium Storage',
  DSOG = 'Discount on Gifts',
  SOS = 'Savings on Shipping',
  CSFD = 'Campaign Store Free Downloads',
}

export enum ActivePlan {
  HRFLT = 'Heartfelt Subscription',
  UNLTD = 'Unlimited Subscription',
  CRD_PKG_100 = '100 Card Package',
  CRD_PKG_250 = '250 Card Package',
  CRD_PKG_500 = '500 Card Package',
  CRD_PKG_1000 = '1000 Card Package',
  CRD_PKG_2000 = '2000 Card Package',
}

export enum LegacyPlan {
  LGCY_980 = '$9.80 Legacy Subscription',
  LGCY_39 = '$39 Legacy Subscription',
}

export type FeatureContent = {
  main:
    | 'included'
    | 'included_with_stamps'
    | 'excluded'
    | (string & Record<never, never>)
  secondary?: {
    content: string
    info?: string // Not sure if this is supposed to give additional context or link to external page OR just shows up when secondary is there. If so remove this
  }
}

export type FeatureSet = {
  [key in FeatureType]: FeatureContent
}

export type PlanData<T> = {
  id: string
  name: T
  sku: string
  mostSimilar?: ActivePlan // For the Legacy Plans that need to be Converted to the Closest Plan
  isFeatured?: boolean
  tableFeatures: FeatureSet
  card: {
    price: string
    features: string[]
    incentive?: {
      title: string
      subtitle: string
      icon: string // Make this a specific Icon name?
    }
  }
}

export type ActivePlans = {
  [key in ActivePlan]: PlanData<key>
}
export type LegacyPlans = {
  [key in LegacyPlan]: PlanData<key>
}
