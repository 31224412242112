import {
  CARDADAY_STRIPE_ID,
  CARD_PACKAGE_100,
  CARD_PACKAGE_1000,
  CARD_PACKAGE_2000,
  CARD_PACKAGE_250,
  CARD_PACKAGE_500,
  LEGACY_39,
  LEGACY_980_1,
  UNLIMITED_STRIPE_ID,
} from 'src/marketing/plans/constants'
import {
  ActivePlan,
  ActivePlans,
  FeatureType,
  LegacyPlan,
  LegacyPlans,
} from './type'

export const tableFeatures: FeatureType[] = Object.values(FeatureType)

export const activePlans: ActivePlans =
  // -------------------Heartfelt Subscription $37------------------------------------
  {
    [ActivePlan.HRFLT]: {
      id: '6',
      sku: CARDADAY_STRIPE_ID,
      name: ActivePlan.HRFLT,
      isFeatured: false,
      card: {
        price: '$37 Per Month',
        features: [
          '20 Monthly Heartfelt Prompting Cards. <b>(Stamps Included)</b>',
          '20% Discount on Gifts.',
          '$1.75 System Cards',
        ],
        incentive: {
          title: 'Upgrade today and get',
          subtitle: '+10 Extra Cards good for 6 months',
          icon: '10_card',
        },
      },
      tableFeatures: {
        [FeatureType.PRICE]: { main: '$37/mo' },
        [FeatureType.PCT_OFF]: { main: 'excluded' },
        [FeatureType.IS_VLD]: { main: 'Unlimited' },
        [FeatureType.HPC]: {
          main: 'included_with_stamps',
          secondary: {
            content: '20/mo',
            info: 'Send 20 Heartfelt Prompting Cards a month.',
          },
        },
        [FeatureType.SC]: {
          main: 'excluded',
          secondary: {
            content: 'Additional Card',
            info:
              'Pay $1.75 for additional cards sent that are not Heartfelt Cards.',
          },
        },
        [FeatureType.AC]: { main: '$1.75' },
        [FeatureType.CM]: { main: 'included' },
        [FeatureType.PS]: { main: 'included' },
        [FeatureType.DSOG]: { main: '20%' },
        [FeatureType.SOS]: { main: 'excluded' },
        [FeatureType.CSFD]: { main: 'excluded' },
      },
    },
    // -------------------Unlimited Subscription $97------------------------------------
    [ActivePlan.UNLTD]: {
      id: '42',
      sku: UNLIMITED_STRIPE_ID,
      name: ActivePlan.UNLTD,
      isFeatured: true,
      card: {
        price: '$97 Per Month',
        features: [
          'Unlimited Heartfelt Promptings Cards & Photo Postcards! <b>(Stamps Included)</b>',
          '30% Off Gifts and Shipping Average.',
          '$1.50 System Cards',
        ],
        incentive: {
          title: 'Upgrade today and get',
          subtitle: '+20 Extra Cards good for 6 months',
          icon: '20_card',
        },
      },
      tableFeatures: {
        [FeatureType.PRICE]: { main: '$97/mo' },
        [FeatureType.PCT_OFF]: { main: 'excluded' },
        [FeatureType.IS_VLD]: { main: 'Unlimited' },
        [FeatureType.HPC]: { main: 'included_with_stamps' },
        [FeatureType.SC]: {
          main: 'excluded',
          secondary: {
            content: 'Additional Card',
            info:
              'Pay $1.50 for additional cards sent that are not Heartfelt Cards',
          },
        },
        [FeatureType.AC]: { main: '$1.50' },
        [FeatureType.CM]: { main: 'included' },
        [FeatureType.PS]: { main: 'included' },
        [FeatureType.DSOG]: { main: '30%' },
        [FeatureType.SOS]: { main: '30%' },
        [FeatureType.CSFD]: { main: 'excluded' },
      },
    },
    // -------------------100 Card Package $157------------------------------------
    [ActivePlan.CRD_PKG_100]: {
      id: '78',
      sku: CARD_PACKAGE_100,
      name: ActivePlan.CRD_PKG_100,
      isFeatured: false,
      card: {
        price: '$157 per package',
        features: [
          '100 Card Credits. <b>(Good for 6 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
          '$1.55 per Additional Card.',
          '15% Discount on Gifts.',
        ],
        incentive: {
          title: 'Upgrade today and get',
          subtitle: '+10 Extra Cards good for 6 months',
          icon: '10_card',
        },
      },
      tableFeatures: {
        [FeatureType.PRICE]: { main: '$157/pkg' },
        [FeatureType.PCT_OFF]: { main: '43%' },
        [FeatureType.IS_VLD]: { main: '6/mo' },
        [FeatureType.HPC]: {
          main: 'included',
          secondary: {
            content: '100 HF or System',
            info:
              'The 100 card credits can be applied to Heartfelt Prompting Cards, System Cards, or Bulk Cards. ',
          },
        },
        [FeatureType.SC]: {
          main: 'included',
          secondary: {
            content: '100 HF or System',
            info:
              'Once the 100 credits are used, additional cards sent will be $1.95.',
          },
        },
        [FeatureType.AC]: { main: '$1.95' },
        [FeatureType.CM]: { main: 'included' },
        [FeatureType.PS]: { main: 'included' },
        [FeatureType.DSOG]: { main: '15%' },
        [FeatureType.SOS]: { main: 'excluded' },
        [FeatureType.CSFD]: { main: 'excluded' },
      },
    },
    // -------------------250 Card Package $347------------------------------------
    [ActivePlan.CRD_PKG_250]: {
      id: '44',
      sku: CARD_PACKAGE_250,
      name: ActivePlan.CRD_PKG_250,
      isFeatured: false,
      card: {
        price: '$347 per package',
        features: [
          '250 Card Credits. <b>(Good for 6 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
          '$1.75 per Additional Card.',
          '20% Discount on Gifts.',
        ],
        incentive: {
          title: 'Upgrade today and get',
          subtitle: '+15 Extra Cards good for 6 months',
          icon: '15_card',
        },
      },
      tableFeatures: {
        [FeatureType.PRICE]: { main: '$347/pkg' },
        [FeatureType.PCT_OFF]: { main: '49%' },
        [FeatureType.IS_VLD]: { main: '6/mo' },
        [FeatureType.HPC]: {
          main: 'included',
          secondary: {
            content: '250 HF or System',
            info:
              'The 250 card credits can be applied to Heartfelt Prompting Cards, System Cards, or Bulk Cards. ',
          },
        },
        [FeatureType.SC]: {
          main: 'included',
          secondary: {
            content: '250 HF or System',
            info:
              'Once the 250 credits are used, additional cards sent will be $1.75.',
          },
        },
        [FeatureType.AC]: { main: '$1.75' },
        [FeatureType.CM]: { main: 'included' },
        [FeatureType.PS]: { main: 'included' },
        [FeatureType.DSOG]: { main: '20%' },
        [FeatureType.SOS]: { main: 'excluded' },
        [FeatureType.CSFD]: { main: 'excluded' },
      },
    },
    // -------------------500 Card Package $597------------------------------------
    [ActivePlan.CRD_PKG_500]: {
      id: '45',
      sku: CARD_PACKAGE_500,
      name: ActivePlan.CRD_PKG_500,
      isFeatured: false,
      card: {
        price: '$597 per package', // TODO follow up on price. Is it $597 or $547 design has different prices
        features: [
          '500 Card Credits. <b>(Good for 6 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
          '$1.55 per Additional Card.',
          '20% Discount on Gifts.',
          'Unlimited Campaign Store Free Downloads',
        ],
        incentive: {
          title: 'Upgrade today and get',
          subtitle: '+20 Extra Cards good for 6 months ',
          icon: '20_card',
        },
      },
      tableFeatures: {
        [FeatureType.PRICE]: { main: '$547/pkg' }, // TODO follow up on price. Is it $597 or $547 design has different prices
        [FeatureType.PCT_OFF]: { main: '57%' },
        [FeatureType.IS_VLD]: { main: '6/mo' },
        [FeatureType.HPC]: {
          main: 'included',
          secondary: {
            content: '500 HF or System',
            info:
              'The 500 card credits can be applied to Heartfelt Prompting Cards, System Cards, or Bulk Cards. ',
          },
        },
        [FeatureType.SC]: {
          main: 'included',
          secondary: {
            content: '500 HF or System',
            info:
              'Once the 500 credits are used, additional cards sent will be $1.55.',
          },
        },
        [FeatureType.AC]: { main: '$1.55' },
        [FeatureType.CM]: { main: 'included' },
        [FeatureType.PS]: { main: 'included' },
        [FeatureType.DSOG]: { main: '20%' },
        [FeatureType.SOS]: { main: 'excluded' },
        [FeatureType.CSFD]: { main: 'included' },
      },
    },
    // -------------------1000 Card Package $997------------------------------------
    [ActivePlan.CRD_PKG_1000]: {
      id: '46',
      sku: CARD_PACKAGE_1000,
      name: ActivePlan.CRD_PKG_1000,
      isFeatured: false,
      card: {
        price: '$997 per package',
        features: [
          '1000 Card Credits. <b>(Good for 12 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
          '$1.35 per Additional Card.',
          '30% Discount on Gifts.',
          'Unlimited Campaign Store Free Downloads',
        ],
        incentive: {
          title: 'Upgrade today and get',
          subtitle: '+25 Extra Cards good for 6 months',
          icon: '25_card',
        },
      },
      tableFeatures: {
        [FeatureType.PRICE]: { main: '$997/pkg' },
        [FeatureType.PCT_OFF]: { main: '65%' },
        [FeatureType.IS_VLD]: { main: '12/mo' },
        [FeatureType.HPC]: {
          main: 'included',
          secondary: {
            content: '1000 HF or System',
            info:
              'The 1000 card credits can be applied to Heartfelt Prompting Cards, System Cards, or Bulk Cards. ',
          },
        },
        [FeatureType.SC]: {
          main: 'included',
          secondary: {
            content: '1000 HF or System',
            info:
              'Once the 1000 credits are used, additional cards sent will be $1.35.',
          },
        },
        [FeatureType.AC]: { main: '$1.35' },
        [FeatureType.CM]: { main: 'included' },
        [FeatureType.PS]: { main: 'included' },
        [FeatureType.DSOG]: { main: '30%' },
        [FeatureType.SOS]: { main: 'excluded' },
        [FeatureType.CSFD]: { main: 'included' },
      },
    },
    // -------------------2000 Card Package $1797------------------------------------
    [ActivePlan.CRD_PKG_2000]: {
      id: '47',
      sku: CARD_PACKAGE_2000,
      name: ActivePlan.CRD_PKG_2000,
      isFeatured: false,
      card: {
        price: '$1797 per package',
        features: [
          '2000 Card Credits. <b>(Good for 12 months and can be applied to: Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
          '$1.15 per Additional Card.',
          '30% Discount on Gifts.',
          'Unlimited Campaign Store Free Downloads',
        ],
        incentive: {
          title: 'Upgrade today and get',
          subtitle: '+30 Extra Cards good for 6 months',
          icon: '30_card',
        },
      },
      tableFeatures: {
        [FeatureType.PRICE]: { main: '$997/pkg' },
        [FeatureType.PCT_OFF]: { main: '67%' },
        [FeatureType.IS_VLD]: { main: '12/mo' },
        [FeatureType.HPC]: {
          main: 'included',
          secondary: {
            content: '2000 HF or System',
            info:
              'The 2000 card credits can be applied to Heartfelt Prompting Cards, System Cards, or Bulk Cards. ',
          },
        },
        [FeatureType.SC]: {
          main: 'included',
          secondary: {
            content: '2000 HF or System',
            info:
              'Once the 2000 credits are used, additional cards sent will be $1.15.',
          },
        },
        [FeatureType.AC]: { main: '$1.15' },
        [FeatureType.CM]: { main: 'included' },
        [FeatureType.PS]: { main: 'included' },
        [FeatureType.DSOG]: { main: '30%' },
        [FeatureType.SOS]: { main: '30%' },
        [FeatureType.CSFD]: { main: 'included' },
      },
    },
  }
export const legacyPlans: LegacyPlans = {
  // -------------------Legacy $9.80------------------------------------
  [LegacyPlan.LGCY_980]: {
    id: '',
    sku: LEGACY_980_1,
    name: LegacyPlan.LGCY_980,
    isFeatured: false,
    mostSimilar: ActivePlan.HRFLT,
    card: {
      price: '$9.80 per month',
      features: [
        '4 Cards per month <b>(Good for 6 months and can be used toward Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        'Stamps not included',
        '$2.45 per additional card',
      ],
    },
    tableFeatures: {
      [FeatureType.PRICE]: { main: '$9.80/mo' },
      [FeatureType.PCT_OFF]: { main: 'excluded' },
      [FeatureType.IS_VLD]: { main: '6/mo' },
      [FeatureType.HPC]: {
        main: 'included',
        secondary: {
          content: '4 HF or System',
          info:
            'Send 4 cards a month. Good for 6 months. Can be used toward HF Prompting Cards, System Cards and Bulk Cards. Stamps not included.',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: '2000 HF or System',
          info:
            'Send 4 cards a month. Good for 6 months. Can be used toward HF Prompting Cards, System Cards and Bulk Cards. $2.45 per additional card.',
        },
      },
      [FeatureType.AC]: { main: '$2.45' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'excluded' },
      [FeatureType.DSOG]: { main: 'excluded' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.CSFD]: { main: 'excluded' },
    },
  },
  // -------------------Legacy $39------------------------------------
  [LegacyPlan.LGCY_39]: {
    id: '',
    sku: LEGACY_39,
    name: LegacyPlan.LGCY_39,
    isFeatured: false,
    mostSimilar: ActivePlan.HRFLT,
    card: {
      price: '$39 per month',
      features: [
        '20 Cards per month <b>(Good for 6 months and can be used toward Heartfelt Prompting Cards, System Cards, and Bulk Cards)</b>',
        'Stamps not included',
        '15% off gifts.',
        '$1.95 per additional card',
      ],
    },
    tableFeatures: {
      [FeatureType.PRICE]: { main: '$39/mo' },
      [FeatureType.PCT_OFF]: { main: 'excluded' },
      [FeatureType.IS_VLD]: { main: '12/mo' },
      [FeatureType.HPC]: {
        main: 'included',
        secondary: {
          content: 'Up to 20/mo',
          info: '//TODO add info content',
        },
      },
      [FeatureType.SC]: {
        main: 'included',
        secondary: {
          content: 'Up to 20/mo',
          info: '//TODO add info content',
        },
      },
      [FeatureType.AC]: { main: '$1.95' },
      [FeatureType.CM]: { main: 'included' },
      [FeatureType.PS]: { main: 'excluded' },
      [FeatureType.DSOG]: { main: 'excluded' },
      [FeatureType.SOS]: { main: 'excluded' },
      [FeatureType.CSFD]: { main: 'excluded' },
    },
  },
}
