import { useWindowSize } from '@react-hook/window-size'
import { Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'

type ComparisonTableProps = {
  children: React.ReactElement<ComparisonColumnProps>[]
}

type ComparisonColumnProps = {
  heading?: React.ReactNode
  hasTitleRows?: boolean
  alignment?: 'left' | 'center' | 'right'
  backgroundColor?: string
  onClick?: () => void
  isActive?: boolean
}

type ComparisonRowProps = {
  backgroundColor?: string
  alignment?: 'left' | 'center' | 'right'
  borderRadius?: string
}

export const ComparisonRow: FC<ComparisonRowProps> = ({
  children,
  backgroundColor,
  alignment = 'left',
  borderRadius,
}) => {
  const justifyItemsAlignment =
    alignment === 'left' ? 'baseline' : alignment === 'right' ? 'end' : 'center'

  return (
    <div
      style={{
        display: 'grid',
        gridRow: 'span 1',
        justifyItems: justifyItemsAlignment,
        alignItems: 'center',
        width: '100%',
        backgroundColor: backgroundColor,
        padding: '12px',
        borderRadius: borderRadius,
        position: 'relative',
      }}
    >
      <Text type="caption" style={{ fontWeight: 600, color: '#000' }}>
        {children}
      </Text>
    </div>
  )
}

export const ComparisonColumn: FC<ComparisonColumnProps> = ({
  children,
  heading,
  hasTitleRows = false,
  alignment = 'center',
  backgroundColor = '#8B8B8B',
  onClick,
  isActive,
}) => {
  const [deviceWidth, _] = useWindowSize()
  const isTabletOrGreater = deviceWidth >= 768

  const rowHeight = 66
  const rowCount = heading
    ? React.Children.count(children) + 1
    : React.Children.count(children)
  const justifyItemsAlignment =
    alignment === 'left' ? 'baseline' : alignment === 'right' ? 'end' : 'center'

  return (
    <div
      onClick={onClick}
      style={{
        display: 'grid',
        gridColumn: 'span 1',
        gridTemplateRows: `repeat(${rowCount},${rowHeight}px)`,
        justifyItems: justifyItemsAlignment,
        width: '100%',
        border: isActive && isTabletOrGreater ? '3px solid #696969' : '',
        borderRadius: isTabletOrGreater ? '8px 8px 0 0' : '',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.04)',
        minWidth: hasTitleRows ? '300px' : undefined,
        position: 'relative',
      }}
    >
      {heading ? (
        <ComparisonRow
          backgroundColor={backgroundColor}
          borderRadius="6px 6px 0 0"
        >
          <Text
            type="footnote"
            color="inverseHeading"
            style={{
              fontWeight: 700,
              fontSize: '.7rem',
            }}
            alignment="center"
            lineHeight={1.1}
          >
            {heading}
          </Text>
        </ComparisonRow>
      ) : (
        hasTitleRows && (
          <ComparisonRow
            backgroundColor={backgroundColor}
            borderRadius="8px 8px 0 0"
          />
        )
      )}
      {children}
    </div>
  )
}

export const ComparisonTable = ({
  children: columns,
}: ComparisonTableProps) => {
  const columnWidth = 104
  const columnCount = React.Children.count(columns)

  const [deviceWidth, _] = useWindowSize()
  const isTabletOrGreater = deviceWidth >= 768

  let mutableTitleColumn

  for (const [index, column] of columns.entries()) {
    if (column.props.hasTitleRows) {
      mutableTitleColumn = [column, index]
      break
    }
  }
  const [titleColumn, titleColumnIndex] = mutableTitleColumn ?? []
  const filteredColumns = columns
    .filter((_column, index) => index !== titleColumnIndex)
    .map(column => ({
      ...column,
      props: { ...column.props, hasTitleRows: false, alignment: 'center' },
    }))

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: isTabletOrGreater
          ? `.65fr repeat(${columnCount - 1}, ${columnWidth}px)`
          : '1fr',
        gap: '16px',
        marginTop: isTabletOrGreater ? '' : '12px',
      }}
    >
      {isTabletOrGreater ? (
        <>
          <div
            style={{
              width: '100%',
              marginTop: isTabletOrGreater ? '' : '12px',
            }}
          >
            {titleColumn}
          </div>
          {filteredColumns}
        </>
      ) : (
        filteredColumns.map((column, index) => {
          const heading = column.props.heading
          const col = {
            ...column,
            props: { ...column.props, heading: undefined, alignment: 'center' },
          }

          const titleCol = {
            ...(titleColumn as React.ReactElement<ComparisonColumnProps>),
          }

          const title = {
            ...titleCol,
            props: {
              ...titleCol.props,
              hasTitleRows: false,
              alignment: 'left',
            },
          }

          return (
            <div
              key={index}
              style={{
                display: 'grid',
                backgroundColor: column.props.backgroundColor,
                borderRadius: '8px',
                border: column.props.isActive ? '3px solid #696969' : '',
                cursor: 'pointer',
              }}
              onClick={column.props.onClick}
            >
              <Text
                type="body"
                color="inverseHeading"
                inset="x1_5"
                style={{ fontWeight: 700 }}
              >
                {heading}
              </Text>
              <div
                key={index}
                style={{
                  display: 'grid',
                  gridTemplateColumns: `1fr ${columnWidth}px`,
                  alignItems: 'center',
                }}
              >
                {title}
                {col}
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}
