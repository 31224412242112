import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type BasicPlanDividerProps = {
  text: string
  textColor?: string
  backgroundColor?: string
  onClick: () => void
}

export const BasicPlanDivider: React.FC<BasicPlanDividerProps> = ({
  text,
  textColor,
  backgroundColor,
  onClick,
}) => {
  return (
    <Flex cursor="pointer" onClick={onClick}>
      <Div borderRadius="circle" inset={{ vertical: 'x1_5', horizontal: 'x3' }}>
        <Text type="footnote" content={text} />
      </Div>
    </Flex>
  )
}
