import { performOperation } from 'src/app/api'
import { GetPlans, GetPlansData, PlanFragment } from 'src/graphql'
import { useEffect, useState } from 'react'

const useGetPlans = () => {
  const [plans, setPlans] = useState<PlanFragment[] | undefined>(undefined)

  useEffect(() => {
    performOperation<GetPlansData>(GetPlans(), result => {
      if (result.error || !result.value) {
        setPlans(undefined)
      } else {
        const plansResponse: PlanFragment[] | undefined = result.value.plans
        setPlans(plansResponse)
      }
    })
  }, [])
  return plans
}

export default useGetPlans
