import {
  Button,
  Div,
  Flex,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { getIconFromPlan } from '../pricingTile/getPlanIcon'
import useGetPlans from '../pricingTile/useGetPlansQuery'
import { useWindowSize } from '@react-hook/window-size'
import { PlanFragment } from 'src/graphql'

type PromoPlanTileType = {
  planId: string
  action: { title: string; onClick: (plan: PlanFragment) => void }
  extraContent?: string
  recurrence?: string
  maxWidth?: string
  minWidth?: string
}

export const PromoPlanTile: React.FC<PromoPlanTileType> = ({
  planId,
  action,
  extraContent,
  recurrence = '/mo',
  minWidth = '60%',
  maxWidth = '775px',
}) => {
  const [deviceWidth, _] = useWindowSize()
  const isTablet = deviceWidth <= 860
  const plans = useGetPlans()
  const plan = plans?.find((plan: PlanFragment) => plan.id === planId)
  if (!plan) return <></>

  return (
    <Flex
      flexDirection="column"
      backgroundColor="foreground"
      borderRadius={'medium'}
      style={{
        borderTop: `4px solid ${plan.backgroundStartColor}`,
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.12)',
      }}
      inset={{ horizontal: 'x4', vertical: 'x3' }}
      justifyContent="center"
      alignItems="flex-start"
      minWidth={isTablet ? '225px' : minWidth}
      maxWidth={isTablet ? '350px' : maxWidth}
      flexWrap="wrap"
    >
      <Flex
        flexDirection={isTablet ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isTablet ? 'center' : 'flex-start'}
        width="100%"
        flexWrap={isTablet ? 'wrap' : 'nowrap'}
      >
        <Flex
          alignItems="center"
          width="100%"
          rowGap="x1"
          columnGap="x1"
          order={isTablet ? 2 : 1}
        >
          {getIconFromPlan(plan, false)}
          <Text
            weight="bold"
            content={plan.title}
            type="body"
            style={{ color: plan.backgroundStartColor }}
          />
        </Flex>

        <Div
          order={isTablet ? 1 : 2}
          backgroundColor="#fff"
          borderRadius="circle"
          boxShadow="light"
          height="fit-content"
          inset={{ horizontal: 'x1_5' }}
          outset={{ bottom: 'auto' }}
          style={{ marginLeft: isTablet ? 0 : 'auto' }}
          minWidth="fit-content"
        >
          <Text
            type="body"
            content={'challenge'}
            lineHeight={2}
            style={{
              fontSize: '.625rem',
              fontWeight: 700,
              color: plan.backgroundStartColor,
            }}
          />
        </Div>
      </Flex>
      <Spacer space="x1" />
      <Flex
        flexDirection={isTablet ? 'column' : 'row'}
        rowGap="x3"
        columnGap="x3"
        alignItems="center"
        flexWrap="wrap"
      >
        <Flex flexDirection="column" maxWidth={isTablet ? '100%' : '60%'}>
          <Flex alignItems="center" width="100%">
            <Text
              content={`$${(plan.price.amount / 100).toString()}`}
              type="title"
              color="primaryBody"
              weight="semiBold"
            />
            <Text
              content={recurrence}
              type="caption"
              style={{ color: '#6B7280' }}
            />
          </Flex>
          <Spacer space="x1" />
          {plan.subtitle && (
            <>
              <Text content={plan.subtitle} type="body" />
              <Spacer space="x1" />
            </>
          )}
          {extraContent && (
            <>
              <Text content={extraContent} type="caption" />
              <Spacer space="x1" />
            </>
          )}
        </Flex>
      </Flex>
      {isTablet && <Spacer space="x2" />}
      <Flex justifyContent={isTablet ? 'center' : 'flex-end'} width="100%">
        <Button
          title={action.title}
          onClick={() => action.onClick(plan)}
          backgroundColorOverride={plan.backgroundStartColor}
          fullWidth={isTablet}
        />
      </Flex>
    </Flex>
  )
}
