import { FeatureItemProps } from './FeatureItem'

export const featureValuesCol1: FeatureItemProps[] = [
  {
    icon: 'exp_calendar',
    featureCopy: [
      {
        text: 'Cards good for 12 months.',
      },
    ],
  },
  {
    icon: 'exp_calendar',
    featureCopy: [
      {
        text: '30% Discount on gifts for 12 months.',
      },
    ],
  },
  {
    icon: 'exp_calendar',
    featureCopy: [
      {
        text: 'Premier Shipping',
      },
      {
        text: '(20% off standard shipping)',
        weight: 'bold',
        color: '#000',
      },
      {
        text: 'for 12 months.',
      },
    ],
  },
]

export const featureValuesCol2: FeatureItemProps[] = [
  {
    icon: 'check_mark',
    featureCopy: [
      {
        text: 'Campaign Store - Lifetime.',
      },
      {
        text: '(Includes complimentary downloads)',
        color: '#000',
        size: '14px',
        weight: 'semiBold',
      },
    ],
  },
  {
    icon: 'check_mark',
    featureCopy: [
      {
        text: 'Relationship Marketing Course - Lifetime.',
      },
    ],
  },
  {
    icon: 'ticket',
    featureCopy: [
      {
        text: '3 Promptings Seminar Tickets.',
      },
    ],
  },
]
