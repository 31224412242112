import React from 'react'
// @src imports
import connect from 'src/redux/connect'
import CardCatalog from 'src/catalog/containers/CardCatalog'
import { openLaunchPage } from 'src/redux/actions/routeAliases'
import { AppRoute } from 'src/app/routes/AppRoute'
import { BaseRoute } from 'src/routes/BaseRoute'
import { PricingRoute } from 'src/pricing_page/routes/PricingRoute'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { PromoRoute } from 'src/promo/routes/PromoRoute'
// marketing pages to decouple
import LaunchPage from 'src/marketing/containers/LaunchPage'
import BusinessPage from 'src/marketing/containers/BusinessPage'
import { AudereRoute } from 'src/marketing/routes/AudereRoute'
import { BusinessRoute } from 'src/marketing/routes/BusinessRoute'
import { TrainingRoute } from 'src/training/routes/TrainingRoute'
import { TrainingPage } from 'src/training/containers/TrainingPage'
import { eventData } from '../training/containers/trainingScheduleData'
import { PromoContextProvider } from 'src/promo/context/promoContext'
import Promo from 'src/promo/containers/Promo'
import PartnershipSplashPage from 'src/marketing/containers/PartnershipSplashPage'
import { PricingPage } from 'src/pricing_page/PricingPage'

type RouterProps = Readonly<{
  route: BaseRoute
  openLaunchPage: () => void
}>

type AppRouterProps = {
  route: AppRoute
}

const AppRouter: React.FC<AppRouterProps> = ({ route }) => {
  if (!route.subroute) {
    return <LaunchPage />
  }
  switch (route.subroute.path) {
    case AudereRoute.path:
      return <PartnershipSplashPage />
    case BusinessRoute.path:
      return <BusinessPage route={route.subroute} />
    case PricingRoute.path:
      return <PricingPage route={route.subroute} />
    case CatalogRoute.path:
      return <CardCatalog route={route.subroute} />
    case PromoRoute.path:
      return (
        <PromoContextProvider>
          <Promo />
        </PromoContextProvider>
      )

    case TrainingRoute.path:
      return <TrainingPage {...eventData} />
  }
}

const Router: React.FC<RouterProps> = ({ route }) => {
  if (!route.subroute) {
    return <LaunchPage />
  }
  switch (route.subroute.path) {
    case AppRoute.path:
      return <AppRouter route={route.subroute} />
    default:
      return <LaunchPage />
  }
}

export default connect(({ route }) => ({ route }), { openLaunchPage })(Router)
