import React from 'react'
import { Div, Flex, Image } from '@sendoutcards/quantum-design-ui'

type CardPreviewType = {
  previewUrl: string
  isHorizontal: boolean
}
type GreetingCardRibbonProps = {
  cards: CardPreviewType[]
  cardWidth: string
  cardHeight: string
  archBasePx?: number
  shouldDisableTransforms?: boolean
  shouldReverseArch?: boolean
}
export const GreetingCardRibbon: React.FC<GreetingCardRibbonProps> = ({
  cards,
  cardWidth,
  cardHeight,
  archBasePx,
  shouldReverseArch,
  shouldDisableTransforms,
}) => {
  return (
    <Flex justifyContent="space-evenly" alignItems="center" width="100%">
      {cards.map((card, index) => {
        const width = card.isHorizontal ? cardHeight : cardWidth
        const height = card.isHorizontal ? cardWidth : cardHeight
        const archMidPoint = Math.floor(cards.length / 2) // Get the midpoint
        const distanceFromMidpoint = Math.abs(index - archMidPoint) // Calculate the arch based on the distance from the midpoint
        const translateY =
          index < archMidPoint
            ? shouldReverseArch
              ? `-${distanceFromMidpoint * (archBasePx || 0)}px`
              : `${distanceFromMidpoint * (archBasePx || 0)}px`
            : shouldReverseArch
            ? `-${distanceFromMidpoint * (archBasePx || 0)}px`
            : `${distanceFromMidpoint * (archBasePx || 0)}px`
        const rotationDeg = index <= archMidPoint ? `15deg` : `-15deg`

        return (
          <Div
            key={index}
            width={width}
            height={height}
            transform={
              shouldDisableTransforms
                ? 'initial'
                : `translateY(${translateY}) rotate(${rotationDeg})`
            }
          >
            <Image
              width="100%"
              height="100%"
              image={{ url: card.previewUrl }}
              isActive={true}
            />
          </Div>
        )
      })}
    </Flex>
  )
}
